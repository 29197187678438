import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

const theme = {
  colors: {
    primary: "#FF5E5E",
    secondary: "#5E5ACD",
    nearBlack: "#0C0000",
    black: "#000000",
    white: "#FFFFFF",
    nearWhite: "#F0F0F0",
    faintWhite: "#CFCFCF",
    lightGrey: "#838383",
    darkGrey: "#282828",
    placeholder: "#dae1ea",
    placeholderFaint: "#e8edf3",
  },
  fonts: {
    publicSans: "Public Sans",
    acuminProExtraCondensed: "acumin-pro-extra-condensed",
    acuminProWide: "acumin-pro-wide",
  },
  fontSizes: {
    tiny: 10,
    small: 12,
    smallish: 14,
    default: 16,
    medium: 18,
    mediumPlus: 24,
    large: 30,
    largePlus: 36,
    giant: 80,
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  lineHeights: {
    smallish: 16,
    smallishPlus: 19,
    default: 28,
    medium: 21,
    mediumPlus: 32,
    large: 36,
    largePlus: 48,
    giant: 64,
  },
  dimensions: {
    infoListItemHeight: 56,
    desktopContentMaxWidth: 1220,
    mobileContentMaxWidth: 360,
    mobileHeaderHeight: 64,
    desktopHeaderHeight: 86,
    mediumAvatar: 72,
  },
  spacing: {
    small: 16,
    medium: 32,
    mediumPlusLess: 40,
    mediumPlus: 52,
  },
  borderRadius: {
    small: 4,
    common: 8,
    large: 16,
    largePlus: 32,
  },
  grid: {
    mobileNumberOfColumns: 4,
    mobileGutterWidth: 24,
    desktopNumberOfColumns: 6,
    desktopGutterWidth: 40,
  },
  zIndexes: {
    header: 1000,
    videoOverlay: 10000,
  },
  darkTheme: false,
}

export default theme

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const GlobalStyle = createGlobalStyle<{
  darkTheme: boolean
}>`
  ${reset}
  
  * {
    box-sizing: border-box;
  }

  body {
    background: ${theme.colors.white};
    background: ${props =>
      props.darkTheme ? theme.colors.darkGrey : theme.colors.white};
    color: ${props =>
      props.darkTheme ? theme.colors.white : theme.colors.darkGrey};
    font-family: ${theme.fonts.publicSans}, sans-serif;
    font-size:  ${theme.fontSizes.default}px;
    line-height: ${theme.lineHeights.default / theme.fontSizes.default};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  input {
    padding: 0;
    margin: 0;
    border: 0;
    font: inherit;
  }

  button {
    width: auto;
    background: none;
    border: 0;
    padding: 0;
    font: inherit;
    
    &:not(:disabled) {
      cursor: pointer;
    }
    
  }
  
  svg {
    display: block;
  }

  a {
    color: inherit;
    text-decoration: inherit;
    
    &:focus {
      outline: none;
    }
    
  }
  
  ul {
    list-style-type: none;
  }

`
