import React, { useContext } from "react"
import { ThemeProvider } from "styled-components"
import theme, { GlobalStyle } from "../../ui/theme"

export interface ThemeHandlerContextState {
  darkTheme: boolean
}

export const ThemeHandlerContext = React.createContext<
  ThemeHandlerContextState
>({
  darkTheme: false,
})

export const useThemeHandler = (): ThemeHandlerContextState => {
  return useContext(ThemeHandlerContext)
}

export const useIsDarkTheme = (): boolean => {
  return useThemeHandler().darkTheme
}

interface Props {
  darkTheme: boolean
}

const ThemeHandler: React.FC<Props> = ({ children, darkTheme }) => (
  <ThemeHandlerContext.Provider value={{ darkTheme }}>
    <GlobalStyle darkTheme={darkTheme} />
    <ThemeProvider
      theme={{
        ...theme,
        darkTheme,
      }}
    >
      {children}
    </ThemeProvider>
  </ThemeHandlerContext.Provider>
)

export default ThemeHandler
