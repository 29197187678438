import React from "react"
import { useIsUserAuthenticated } from "../../auth/components/AuthHandler/AuthHandler"

const AuthRestricted: React.FC<{
  authenticatedOnly?: boolean
}> = ({ children, authenticatedOnly = true }) => {
  const isAuthenticated = useIsUserAuthenticated()
  if (authenticatedOnly && !isAuthenticated) return null
  if (!authenticatedOnly && isAuthenticated) return null
  return <>{children}</>
}

export default AuthRestricted
