import React, { useContext, useState, useEffect } from "react"
import { ICMSCoach } from "../../../../data/cms/coach"
import {
  getCoachStripeOffering,
  IFirestoreOfferingDoc,
} from "../../../../firebase/offerings"

export interface BillingAddressInput {
  line1: string
  line2: string
  city: string
  country: string
  state: string
  postalCode: string
}

export interface SignUpContextState {
  basePath: string
  coachOffering: IFirestoreOfferingDoc | null
  billingAddress: BillingAddressInput
  setBillingAddress: (billingAddress: BillingAddressInput) => void
}

export const SignUpContext = React.createContext<SignUpContextState>({
  basePath: "",
  coachOffering: null,
  billingAddress: {
    line1: "",
    line2: "",
    city: "",
    country: "",
    state: "",
    postalCode: "",
  },
  setBillingAddress: () => {},
})

export const useSignUpContext = (): SignUpContextState => {
  return useContext(SignUpContext)
}

export const useSignUpBasePath = (): string => {
  return useSignUpContext().basePath
}

const SignUpContextHandler: React.FC<{
  basePath: string
  coach?: ICMSCoach
}> = ({ basePath, coach, children }) => {
  const [
    coachOffering,
    setCoachOffering,
  ] = useState<IFirestoreOfferingDoc | null>(null)
  const [billingAddress, setBillingAddress] = useState<BillingAddressInput>({
    line1: "",
    line2: "",
    city: "",
    country: "",
    state: "",
    postalCode: "",
  })

  const getCoachOffering = async () => {
    const firestoreOffering = coach
      ? await getCoachStripeOffering(coach.coachId)
      : null
    return firestoreOffering
  }

  useEffect(() => {
    getCoachOffering().then(offering => {
      setCoachOffering(offering)
    })
  }, [])

  return (
    <SignUpContext.Provider
      value={{ basePath, coachOffering, billingAddress, setBillingAddress }}
    >
      {children}
    </SignUpContext.Provider>
  )
}

export default SignUpContextHandler
