import React from "react"
import styled from "styled-components"
import MediumHeading from "../../../../ui/typography/MediumHeading/MediumHeading"
import theme from "../../../../ui/theme"
import FAQ from "../../../../components/FAQ/FAQ"
import { ICMSCoach } from "../../../../data/cms/coach"

export const StyledList = styled.ul`
  line-height: 28px;
  margin-top: ${theme.spacing.small}px;

  li {
    border-bottom: 1px solid ${theme.colors.lightGrey};

    &:first-child {
      border-top: 1px solid ${theme.colors.lightGrey};
    }
  }
`

const StyledContainer = styled.section``

const CoachFAQS: React.FC<{
  faqs: ICMSCoach["faqs"]
}> = ({ faqs, ...props }) => (
  <StyledContainer {...props}>
    <MediumHeading as="h3">FAQs</MediumHeading>
    <StyledList>
      {faqs.map(({ question, answer }, index) => (
        <li key={index.toString()}>
          <FAQ question={question} answer={answer} />
        </li>
      ))}
    </StyledList>
  </StyledContainer>
)

export default CoachFAQS
