import React from "react"
import styled, { css } from "styled-components"
import Input from "../Input/Input"
import theme from "../../theme"
import Tick from "../../../assets/inline-assets/tick.svg"
import Close from "../../../assets/inline-assets/close.svg"

const invalidCss = css`
  border-color: ${theme.colors.primary};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`

export const StyledInput: any = styled(Input)<any>`
  background: none;
  border: 1px solid ${theme.colors.lightGrey};
  display: block;
  width: 100%;
  padding: 21px 32px 7px 16px;
  border-radius: ${theme.borderRadius.common}px;
  color: ${theme.colors.white};

  &:focus {
    outline: none;
    border-color: ${theme.colors.secondary};
  }
  ${props => (props.invalid ? invalidCss : "")};
`

const StyledContainer = styled.div`
  position: relative;
`

const emptyCss = css`
  transform: translateY(15px);

  input:focus + & {
    transform: translateY(0);
  }
`

const StyledLabel = styled.label<{
  empty: boolean
}>`
  font-size: ${theme.fontSizes.smallish}px;
  line-height: ${theme.lineHeights.smallish}px;
  color: ${theme.colors.lightGrey};
  position: absolute;
  top: 6px;
  left: 16px;

  input:focus + & {
    color: ${theme.colors.white};
  }

  ${props => (props.empty ? emptyCss : "")};
`

const StyledInvalidMessage = styled.div`
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  border-bottom-left-radius: ${theme.borderRadius.common}px;
  border-bottom-right-radius: ${theme.borderRadius.common}px;
  font-size: 14px;
  line-height: 20px;
  padding: 5px ${theme.spacing.small}px 7px ${theme.spacing.small}px;
`

const StyledValidityIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 16px;

  svg {
    width: 19px;
    height: 19px;
  }
`

const StyledRequiredMessage = styled.div`
  position: absolute;
  top: 19px;
  right: 16px;
  font-size: 13px;
`

interface Props extends React.HTMLProps<HTMLInputElement> {
  value: string
  label: string
  type: string
  name: string
  errorMessage?: string
  invalid?: boolean
  valid?: boolean
  required?: boolean
}

const ValidatedInput: React.FC<Props> = ({
  value,
  name,
  label,
  type,
  invalid = false,
  valid = false,
  required = false,
  errorMessage = "",
  ...props
}) => {
  const empty = !value

  let rightSide = null

  if (valid) {
    rightSide = (
      <StyledValidityIcon>
        <Tick />
      </StyledValidityIcon>
    )
  } else if (invalid) {
    rightSide = (
      <StyledValidityIcon>
        <Close />
      </StyledValidityIcon>
    )
  } else if (required) {
    rightSide = <StyledRequiredMessage>Required</StyledRequiredMessage>
  }

  return (
    <StyledContainer>
      {rightSide}
      <StyledInput
        id={name}
        name={name}
        type={type}
        invalid={invalid}
        value={value}
        {...props}
      />
      <StyledLabel htmlFor={name} empty={empty}>
        {label}
      </StyledLabel>
      {invalid && <StyledInvalidMessage>{errorMessage}</StyledInvalidMessage>}
    </StyledContainer>
  )
}

export default ValidatedInput
