import { isFirebasePrdEnv } from "../utils/env"

export const TEMP_ROUTES = {
  home: {
    path: "/",
    name: "home",
  },
  about: {
    path: "/about",
    name: "about",
  },
  terms: {
    path: "/terms",
    name: "terms",
  },
  privacy: {
    path: "/privacy",
    name: "privacy",
  },
  discover: {
    path: "/discover",
    name: "discover",
  },
  signUp: {
    path: "/signup",
    subPath: "/",
    paramPath: (destination: string) => `/signup?d=${destination}`,
    name: "signUp",
  },
  signUpCreate: {
    path: "/signup/create",
    subPath: "/create",
  },
  signUpCreateEmail: {
    path: "/signup/create/email",
    subPath: "/create/email",
  },
  signUpBilling: {
    path: "/signup/billing",
    subPath: "/billing",
  },
  signUpPayment: {
    path: "/signup/payment",
    subPath: "/payment",
  },
  signUpAccount: {
    path: "/signup/account",
    subPath: "/account",
  },
  signUpDetails: {
    path: "/signup/details",
    subPath: "/details",
  },
  signUpCompleted: {
    path: "/signup/completed",
    subPath: "/completed",
  },
  account: {
    path: "/account",
    subPath: "/",
    name: "account",
  },
  accountSettings: {
    path: "/account/settings",
    subPath: "/settings",
    name: "userSettings",
  },
  accountSettingsPassword: {
    path: "/account/settings/password",
    subPath: "/settings/password",
    name: "userSettingsPassword",
  },
  accountSettingsUsername: {
    path: "/account/settings/username",
    subPath: "/settings/username",
    name: "userSettingsUsername",
  },
  accountSubscriptions: {
    path: "/account/mycoaches",
    subPath: "/mycoaches",
    name: "myCoaches",
  },
  accountShortlist: {
    path: "/account/shortlist",
    subPath: "/shortlist",
    name: "shortlistedCoaches",
  },
  signIn: {
    path: "/signin",
    paramPath: (destination: string) => `/signin?d=${destination}`,
    name: "signIn",
  },
  signOut: {
    path: "/signout",
    name: "signOut",
  },
  vods: {
    path: "/vods",
    name: "coachVOD",
  },
  coach: {
    path: "/coach/$SLUG$",
    paramPath: (slug: string) => `/coach/${slug}`,
    name: "coachProfile",
  },
  coachTopic: {
    path: "/coach/$SLUG$/topic/$TOPIC$",
    paramPath: (slug: string, topic: string) => `/coach/${slug}/topic/${topic}`,
    subPath: "/topic/:topic",
    paramSubPath: (topic: string) => `/topic/${topic}`,
    name: "coachTopic",
  },
  coachSignup: {
    path: "/coach/$SLUG$/signup",
    paramPath: (slug: string, destination?: string) =>
      `/coach/${slug}/signup${destination ? `?d=${destination}` : ""}`,
    subPath: "/signup/*",
  },
  coachSignupBilling: {
    path: "/coach/$SLUG$/signup/billing",
    paramPath: (slug: string, destination?: string) =>
      `/coach/${slug}/signup/billing${destination ? `?d=${destination}` : ""}`,
  },
  coachSignupPayment: {
    path: "/coach/$SLUG$/signup/payment",
    paramPath: (slug: string, destination?: string) =>
      `/coach/${slug}/signup/payment${destination ? `?d=${destination}` : ""}`,
  },
  coachSignUpScreen: {
    path: "/for-coaches",
    name: "coachSignup",
  },
  coachSignUpApplicationScreen: {
    path: "/coach-signup-application",
    name: "coachApplication",
  },
  help: {
    path: "/help",
    name: "helpCenter",
  },
}

export const FULL_ROUTES = {
  home: {
    path: "/",
    name: "home",
  },
  about: {
    path: "/about",
    name: "about",
  },
  terms: {
    path: "/terms",
    name: "terms",
  },
  privacy: {
    path: "/privacy",
    name: "privacy",
  },
  discover: {
    path: "/discover",
    name: "discover",
  },
  signUp: {
    path: "/signup",
    subPath: "/",
    paramPath: (destination: string) => `/signup?d=${destination}`,
    name: "signUp",
  },
  signUpCreate: {
    path: "/signup/create",
    subPath: "/create",
  },
  signUpCreateEmail: {
    path: "/signup/create/email",
    subPath: "/create/email",
  },
  signUpBilling: {
    path: "/signup/billing",
    subPath: "/billing",
  },
  signUpPayment: {
    path: "/signup/payment",
    subPath: "/payment",
  },
  signUpAccount: {
    path: "/signup/account",
    subPath: "/account",
  },
  signUpDetails: {
    path: "/signup/details",
    subPath: "/details",
  },
  signUpCompleted: {
    path: "/signup/completed",
    subPath: "/completed",
  },
  account: {
    path: "/account",
    subPath: "/",
    name: "account",
  },
  accountSettings: {
    path: "/account/settings",
    subPath: "/settings",
    name: "userSettings",
  },
  accountSettingsPassword: {
    path: "/account/settings/password",
    subPath: "/settings/password",
    name: "userSettingsPassword",
  },
  accountSettingsUsername: {
    path: "/account/settings/username",
    subPath: "/settings/username",
    name: "userSettingsUsername",
  },
  accountSubscriptions: {
    path: "/account/mycoaches ",
    subPath: "/mycoaches",
    name: "myCoaches",
  },
  accountShortlist: {
    path: "/account/shortlist",
    subPath: "/shortlist",
    name: "shortlistedCoaches",
  },
  signIn: {
    path: "/signin",
    paramPath: (destination: string) => `/signin?d=${destination}`,
    name: "signIn",
  },
  signOut: {
    path: "/signout",
    name: "signOut",
  },
  vods: {
    path: "/vods",
    name: "coachVOD",
  },
  coach: {
    path: "/coach/$SLUG$",
    paramPath: (slug: string) => `/coach/${slug}`,
    name: "coachProfile",
  },
  coachTopic: {
    path: "/coach/$SLUG$/topic/$TOPIC$",
    paramPath: (slug: string, topic: string) => `/coach/${slug}/topic/${topic}`,
    subPath: "/topic/:topic",
    paramSubPath: (topic: string) => `/topic/${topic}`,
    name: "coachTopic",
  },
  coachSignup: {
    path: "/coach/$SLUG$/signup",
    paramPath: (slug: string, destination?: string) =>
      `/coach/${slug}/signup${destination ? `?d=${destination}` : ""}`,
    subPath: "/signup/*",
  },
  coachSignupBilling: {
    path: "/coach/$SLUG$/signup/billing",
    paramPath: (slug: string, destination?: string) =>
      `/coach/${slug}/signup/billing${destination ? `?d=${destination}` : ""}`,
  },
  coachSignupPayment: {
    path: "/coach/$SLUG$/signup/payment",
    paramPath: (slug: string, destination?: string) =>
      `/coach/${slug}/signup/payment${destination ? `?d=${destination}` : ""}`,
  },
  coachSignUpScreen: {
    path: "/for-coaches",
    name: "coachSignup",
  },
  coachSignUpApplicationScreen: {
    path: "/coach-signup-application",
    name: "coachApplication",
  },
  help: {
    path: "/help",
    name: "helpCenter",
  },
}

export const ROUTES = isFirebasePrdEnv() ? TEMP_ROUTES : FULL_ROUTES
