export const isCmsDevEnv = (): boolean => {
  return process.env.GATSBY_CMS_CONTENT_ENV === "dev"
}

export const isCmsStgEnv = (): boolean => {
  return process.env.GATSBY_CMS_CONTENT_ENV === "stg"
}

export const isCmsUatEnv = (): boolean => {
  return process.env.GATSBY_CMS_CONTENT_ENV === "uat"
}

export const isCmsPrdEnv = (): boolean => {
  return process.env.GATSBY_CMS_CONTENT_ENV === "prd"
}

export const isFirebaseDevEnv = (): boolean => {
  return process.env.GATSBY_FIREBASE_ENV === "dev"
}

export const isFirebaseStgEnv = (): boolean => {
  return process.env.GATSBY_FIREBASE_ENV === "stg"
}

export const isFirebaseUatEnv = (): boolean => {
  return process.env.GATSBY_FIREBASE_ENV === "uat"
}

export const isFirebasePrdEnv = (): boolean => {
  return process.env.GATSBY_FIREBASE_ENV === "prd"
}

export const getEnvKey = ({
  devKey = "",
  stgKey = "",
  uatKey = "",
  prdKey = "",
  defaultKey = "",
}: {
  devKey?: string
  stgKey?: string
  uatKey?: string
  prdKey?: string
  defaultKey?: string
}): string => {
  if (devKey && isCmsDevEnv()) return devKey
  if (stgKey && isCmsStgEnv()) return stgKey
  if (uatKey && isCmsUatEnv()) return uatKey
  if (prdKey && isCmsPrdEnv()) return prdKey
  return defaultKey
}
