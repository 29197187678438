import styled, { css } from "styled-components"
import theme from "../../theme"
import { DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT } from "../../responsive"

export const maxWidthContentCss = css`
  padding-left: 10px;
  padding-right: 10px;
  max-width: ${theme.dimensions.mobileContentMaxWidth + 20}px;
  margin-left: auto;
  margin-right: auto;
`

export const desktopMaxWidthContentCss = css`
  padding-left: 10px;
  padding-right: 10px;
  max-width: ${theme.dimensions.desktopContentMaxWidth + 20}px;
  margin-left: auto;
  margin-right: auto;
`

export const DesktopMaxWidthContent = styled.div`
  ${desktopMaxWidthContentCss};
`

export const ResponsiveMaxWidthContent = styled.div`
  ${MOBILE_BREAKPOINT} {
    ${maxWidthContentCss}
  }
  ${DESKTOP_BREAKPOINT} {
    ${desktopMaxWidthContentCss};
  }
`

export const MobileOnlyMaxWidthContent = styled.div`
  ${MOBILE_BREAKPOINT} {
    ${maxWidthContentCss}
  }
`

const MaxWidthContent = styled.div`
  ${maxWidthContentCss}
`

export default MaxWidthContent
