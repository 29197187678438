import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { get } from "lodash"
import {
  getDefaultFooterCta1Destination,
  getDefaultFooterCta1Label,
  getDefaultFooterCta2Destination,
  getDefaultFooterCta2Label,
  getMenuApplyToCoach,
  getMenuForCoachesUrl,
  ICMSMetaSocial,
} from "../../../../data/cms/socialMeta"
import { getEnvKey } from "../../../../utils/env"

export interface ISiteContent {
  defaultFooterCta1Label: string
  defaultFooterCta1Destination: string
  defaultFooterCta2Label: string
  defaultFooterCta2Destination: string
  menuForCoachesUrl: string
  menuApplyToCoachUrl: string
}

const SiteContentContext = React.createContext<ISiteContent>({
  defaultFooterCta1Label: "",
  defaultFooterCta1Destination: "",
  defaultFooterCta2Label: "",
  defaultFooterCta2Destination: "",
  menuForCoachesUrl: "",
  menuApplyToCoachUrl: "",
})

export const useSiteContent = (): ISiteContent => {
  return useContext(SiteContentContext)
}

const SiteContentWrapper: React.FC = ({ children }) => {
  const response: {
    datoCmsWebMetaSocialDev?: ICMSMetaSocial
    datoCmsWebMetaSocialStg?: ICMSMetaSocial
    datoCmsWebMetaSocial?: ICMSMetaSocial
    datoCmsWebMetaSocialPrd?: ICMSMetaSocial
  } = useStaticQuery(graphql`
    query SiteContentQuery {
      datoCmsWebMetaSocialDev {
        ...WebMetaSocialDev
      }
      datoCmsWebMetaSocialStg {
        ...WebMetaSocialStg
      }
      datoCmsWebMetaSocialPrd {
        ...WebMetaSocialPrd
      }
      datoCmsWebMetaSocial {
        ...WebMetaSocial
      }
    }
  `)
  const dataKey = getEnvKey({
    devKey: "datoCmsWebMetaSocialDev",
    stgKey: "datoCmsWebMetaSocialStg",
    uatKey: "datoCmsWebMetaSocial",
    prdKey: "datoCmsWebMetaSocialPrd",
    defaultKey: "datoCmsWebMetaSocial",
  })
  const data: ICMSMetaSocial = get(response, `${dataKey}`)
  return (
    <SiteContentContext.Provider
      value={{
        defaultFooterCta1Label: getDefaultFooterCta1Label(data),
        defaultFooterCta1Destination: getDefaultFooterCta1Destination(data),
        defaultFooterCta2Label: getDefaultFooterCta2Label(data),
        defaultFooterCta2Destination: getDefaultFooterCta2Destination(data),
        menuForCoachesUrl: getMenuForCoachesUrl(data),
        menuApplyToCoachUrl: getMenuApplyToCoach(data),
      }}
    >
      {children}
    </SiteContentContext.Provider>
  )
}

export default SiteContentWrapper
