import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import LeftArrow from "../../../../assets/inline-assets/arrowLeft.svg"
import { StyledContainer, StyledStepHeader } from "../InfoStep/InfoStep"
import theme from "../../../../ui/theme"

export const StyledBackButton = styled.button`
  position: absolute;
  left: 0;
  //top: 0;
  bottom: 0;

  svg {
    width: 18px;

    path {
      stroke: ${theme.colors.primary};
    }
  }
`

const CreateStep: React.FC<{
  back: string
  firstStep: boolean
}> = ({ children, back, firstStep }) => {
  return (
    <StyledContainer>
      <StyledStepHeader>
        {!!back && (
          <StyledBackButton as={Link} to={back}>
            <LeftArrow />
          </StyledBackButton>
        )}
        {/* <div>Step {firstStep ? "One" : "Two"}</div> */}
      </StyledStepHeader>
      {children}
    </StyledContainer>
  )
}

export default CreateStep
