/* eslint-disable no-nested-ternary */
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import * as firebase from "firebase/app"
import {
  isFirebaseDevEnv,
  isFirebasePrdEnv,
  isFirebaseUatEnv,
} from "../utils/env"

require("firebase/auth")
require("firebase/firestore")
require("firebase/storage")

const credentials = isFirebasePrdEnv()
  ? require("../../credentials/youspired-prd-firebase-client.json")
  : isFirebaseDevEnv()
  ? require("../../credentials/youspired-dev-firebase-client.json")
  : isFirebaseUatEnv()
  ? require("../../credentials/youspired-uat-firebase-client.json")
  : require("../../credentials/youspired-stg-firebase-client.json")

export const firebaseApp = firebase.initializeApp(credentials)

export const firebaseDatabase = firebaseApp.firestore()

export const firebaseStorage = firebaseApp.storage()
