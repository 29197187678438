import React, { useContext, useEffect, useState } from "react"
import ls from "local-storage"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import firebase from "firebase/app"
import { firebaseApp } from "../../../firebase/client"
import {
  clearStoredUser,
  getFirestoreUserEmail,
  getFirestoreUserUsername,
  getStoredUser,
  getUserDoc,
  IFirestoreUserDoc,
  storeUser,
} from "../../../firebase/user"
import {
  mixpanelIdentify,
  mixpanelRegisterProfileSuperProperties,
} from "../../../analytics/mixpanel"
import { gaSetUser } from "../../../analytics/google"

const KEY_AUTHENTICATED = "KEY_AUTHENTICATED"

const isAuthenticated = (): boolean => {
  return ls.get(KEY_AUTHENTICATED) === true
}

export interface AuthHandlerContextState {
  authenticated: boolean
  firebaseAuthenticated: boolean
  setAuthenticated: (authenticated: boolean) => void
  fbUser: firebase.User | null
  fbDatabaseUser: IFirestoreUserDoc | null
  refetchFbUser: () => void
}

export const AuthHandlerContext = React.createContext<AuthHandlerContextState>({
  authenticated: false,
  firebaseAuthenticated: false,
  setAuthenticated: () => {},
  fbUser: null,
  fbDatabaseUser: null,
  refetchFbUser: () => {},
})

export const useAuthHandlerContext = (): AuthHandlerContextState => {
  return useContext(AuthHandlerContext)
}

export const useIsUserAuthenticated = (): boolean => {
  return useAuthHandlerContext().authenticated
}

export const useIsUserFirebaseAuthenticated = (): boolean => {
  return useAuthHandlerContext().firebaseAuthenticated
}

export const useFirebaseUser = (): firebase.User | null => {
  return useAuthHandlerContext().fbUser
}

export const isFirebaseUserPasswordSignUp = (user: firebase.User): boolean => {
  const { providerData = [] } = user
  return providerData
    .map(data => (data ? data.providerId : ""))
    .includes("password")
}

export const getFirebaseUserEmail = (user: firebase.User): string | null => {
  const { providerData = [] } = user
  const passwordProvider = providerData.find(data => {
    return data && data.providerId === "password"
  })
  return passwordProvider ? passwordProvider.email : null
}

export const useIsFirebaseUserPasswordSignUp = (): boolean => {
  const user = useFirebaseUser()
  if (!user) return false
  return isFirebaseUserPasswordSignUp(user)
}

export const useFirebaseDbUser = (): IFirestoreUserDoc | null => {
  return useAuthHandlerContext().fbDatabaseUser
}

export const useUserUid = (): string => {
  const user = useFirebaseUser()
  if (user) {
    return user.uid
  }
  return ""
}

export const useIsAuthenticatedCoach = (): boolean => {
  const user = useFirebaseDbUser()
  if (!user) return false
  return user.userType === "coach"
}

const AuthHandler: React.FC = ({ children }) => {
  const mixpanel = useMixpanel()
  const [authenticated, setAuthenticated] = useState(isAuthenticated())
  const [firebaseAuthenticated, setFirebaseAuthenticated] = useState(false)
  const [fbUser, setFBUser] = useState<firebase.User | null>(null)
  const [
    fbDatabaseUser,
    setFBDatabaseUser,
  ] = useState<IFirestoreUserDoc | null>(getStoredUser())

  const handleUserIsSignedIn = () => {
    setFirebaseAuthenticated(true)
    setAuthenticated(true)
  }

  const handleUserIsSignedOut = () => {
    setFirebaseAuthenticated(false)
    setAuthenticated(false)
    setFBUser(null)
    setFBDatabaseUser(null)
    clearStoredUser()
  }

  useEffect(() => {
    let unsubscribe: any
    if (fbUser) {
      unsubscribe = getUserDoc(fbUser.uid).onSnapshot(doc => {
        const user = doc.data()
        // console.log("firestore user", user)
        if (user) {
          const username = getFirestoreUserUsername(user as IFirestoreUserDoc)
          const email = getFirestoreUserEmail(user as IFirestoreUserDoc)
          mixpanelRegisterProfileSuperProperties(
            mixpanel,
            fbUser.uid,
            email,
            username
          )
          storeUser(user as IFirestoreUserDoc)
          setFBDatabaseUser(user as IFirestoreUserDoc)
        }
      })
    }
    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [fbUser])

  useEffect(() => {
    ls.set(KEY_AUTHENTICATED, authenticated)
  }, [authenticated])

  const refetchFbUser = async () => {
    const user = await firebase.auth().currentUser
    if (user) {
      setFBUser(user)
    }
  }

  useEffect(() => {
    firebaseApp.auth().onAuthStateChanged(function(user) {
      setFBUser(user)
      if (user) {
        mixpanelIdentify(mixpanel, user.uid)
        handleUserIsSignedIn()
        gaSetUser(user.uid)
      } else {
        // console.log("no user...")
        handleUserIsSignedOut()
      }
    })
  }, [])

  return (
    <AuthHandlerContext.Provider
      value={{
        authenticated,
        firebaseAuthenticated,
        setAuthenticated,
        fbUser,
        fbDatabaseUser,
        refetchFbUser,
      }}
    >
      {children}
    </AuthHandlerContext.Provider>
  )
}

export default AuthHandler
