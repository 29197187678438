import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import RawModal from "../../ui/components/RawModal/RawModal"
import theme from "../../ui/theme"
import LogoButton from "../../ui/buttons/LogoButton/LogoButton"
import CloseIcon from "../../assets/inline-assets/close.svg"
import { ROUTES } from "../../navigation/routes"
import FacebookIcon from "../../assets/inline-assets/facebookMenuIcon.svg"
import InstagramIcon from "../../assets/inline-assets/instagramMenuIcon.svg"
import AuthRestricted from "../AuthRestricted/AuthRestricted"
import { useSocialLinks } from "../SocialLinks/SocialLinks"
import { DESKTOP_BREAKPOINT, hideOnDesktopCss, MOBILE_BREAKPOINT, showOnDesktopCss } from "../../ui/responsive"
import { desktopMaxWidthContentCss } from "../../ui/components/MaxWidthContent/MaxWidthContent"
import { useHelpUrl } from "../ForgotDetailsModal/ForgotDetailsModal"
import { useSiteContent } from "../SiteWrapper/components/SiteContentWrapper/SiteContentWrapper"
import { StyledAppleButton } from "../../screens/CoachSignUpScreen/components/DarkSection/DarkSection"
import { isFirebasePrdEnv } from "../../utils/env"
import { useIsAuthenticatedCoach } from "../../auth/components/AuthHandler/AuthHandler"

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
`

const StyledContentWrapper = styled.div`
  ${DESKTOP_BREAKPOINT} {
    ${desktopMaxWidthContentCss};
    display: grid;
    grid-template-columns: repeat(${theme.grid.desktopNumberOfColumns}, 1fr);
    grid-column-gap: ${theme.grid.desktopGutterWidth}px;
  }
`

const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  a:hover {
    text-decoration: underline;
  }

  ${MOBILE_BREAKPOINT} {
    padding: 0 26px;
  }

  ${DESKTOP_BREAKPOINT} {
    grid-column-start: 2;
    grid-column-end: 6;
  }
`

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;

  ${DESKTOP_BREAKPOINT} {
    margin-top: 23px;
  }
`

const StyledLinksContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  font-size: ${theme.fontSizes.medium}px;
  line-height: 28px;
  padding-top: 55px;

  a {
    text-decoration: none;
  }
`

const StyledHR = styled.hr`
  background: white;
  margin: ${theme.spacing.mediumPlusLess}px 0;

  ${DESKTOP_BREAKPOINT} {
    margin-top: 52px;
  }
`

const StyledLinks = styled.ul`
  li {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
`

const StyledAdditionalLinks = styled.ul`
  li {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
`

const StyledMainLinksContainer = styled.div`
  font-weight: ${theme.fontWeights.bold};
  ${MOBILE_BREAKPOINT} {
    display: flex;
  }

  ${DESKTOP_BREAKPOINT} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: ${theme.grid.desktopGutterWidth}px;
    font-size: ${theme.fontSizes.mediumPlus}px;
    line-height: 30px;
  }

  > ul {
    ${MOBILE_BREAKPOINT} {
      &:first-child {
        min-width: 200px;
        margin-right: 20px;
      }
    }
    ${DESKTOP_BREAKPOINT} {
      grid-column: span 2;
    }
  }
`

const StyledLogo = styled.div`
  margin-left: -11px;
  svg {
    g {
      fill: ${theme.colors.white};
    }
  }
`

const StyledCloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-right: -7px;

  svg {
    width: 20px;
    height: 20px;

    path {
      stroke: ${theme.colors.white};
    }
  }
`

const StyledFooter = styled.ul`
  display: flex;
  align-items: center;

  ${MOBILE_BREAKPOINT} {
    margin-top: 38.5px;
    padding-top: 10px;
  }

  ${DESKTOP_BREAKPOINT} {
    margin-top: 48.5px;
  }

  li {
    margin-right: 40px;
  }
`

const HideOnDesktopLink = styled.a`
  ${hideOnDesktopCss}
`

const HideOnMobileLink = styled.a`
  ${showOnDesktopCss}
`

const SiteMenu: React.FC<{
  onClose: () => void
}> = ({ onClose }) => {
  const { facebook, twitter, instagram, downloadApp } = useSocialLinks()
  const { menuApplyToCoachUrl, menuForCoachesUrl } = useSiteContent()
  const helpUrl = useHelpUrl()
  const isCoach = useIsAuthenticatedCoach();

  return (
    <RawModal fullScreen onClose={onClose}>
      <StyledContainer>
        <StyledContentWrapper>
          <StyledContent>
            <StyledHeader>
              <StyledLogo>
                <LogoButton as={Link} to={ROUTES.home.path} desktopHideFull />
              </StyledLogo>
              <StyledCloseButton onClick={onClose}>
                <CloseIcon />
              </StyledCloseButton>
            </StyledHeader>
            <StyledLinksContainer>
              <StyledMainLinksContainer>
                <StyledLinks>
                  <li>
                    <Link to={ROUTES.home.path}>Home</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.discover.path}>Discover</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.about.path}>About</Link>
                  </li>
                  <li>
                    <a href={menuForCoachesUrl}>For Coaches</a>
                  </li>
                  <li>
                    <a
                      href={menuApplyToCoachUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Apply to Coach
                    </a>
                  </li>
                  <AuthRestricted>
                    {isCoach && (
                      <li>
                        <HideOnDesktopLink href="https://coach.youspired.com/welcome" target="_blank">Coach Training</HideOnDesktopLink>
                      </li>
                    )}
                    <li>
                      <HideOnDesktopLink href={downloadApp} target="_blank">Download iPhone App</HideOnDesktopLink>
                    </li>
                  </AuthRestricted>
                  {/* <li> */}
                  {/*  <a */}
                  {/*    href={downloadApp} */}
                  {/*    target="_blank" */}
                  {/*    rel="noopener noreferrer" */}
                  {/*  > */}
                  {/*    Download App */}
                  {/*  </a> */}
                  {/* </li> */}
                </StyledLinks>
                <StyledLinks>
                  <AuthRestricted>
                    <li>
                      <Link to={ROUTES.account.path}>My Account</Link>
                    </li>
                    <li>
                      <Link to={ROUTES.signOut.path}>Sign Out</Link>
                    </li>
                    {isCoach && (
                      <li>
                        <HideOnMobileLink href="https://coach.youspired.com/welcome" target="_blank">Coach Training</HideOnMobileLink>
                      </li>
                    )}
                  </AuthRestricted>
                  <AuthRestricted authenticatedOnly={false}>
                    <>
                      <li>
                        <Link to={ROUTES.signUp.path}>Sign Up</Link>
                      </li>

                      <li>
                        <Link to={ROUTES.signIn.path}>Sign In</Link>
                      </li>
                    </>
                  </AuthRestricted>
                </StyledLinks>
              </StyledMainLinksContainer>
              <StyledHR />
              <StyledAdditionalLinks>
                {/* <li> */}
                {/*  <Link to={ROUTES.about.path}>About</Link> */}
                {/* </li> */}
                <li>
                  <a href={helpUrl}>Help</a>
                </li>
                <li>
                  <Link to={ROUTES.terms.path}>Terms and Conditions</Link>
                </li>
                <li>
                  <Link to={ROUTES.privacy.path}>Privacy Policy</Link>
                </li>
              </StyledAdditionalLinks>
            </StyledLinksContainer>
            <StyledFooter>
              <li>
                <a href={facebook} target="_blank" rel="noopener noreferrer">
                  <FacebookIcon />
                </a>
              </li>
              {/* <li> */}
              {/*  <a href={twitter} target="_blank" rel="noopener noreferrer"> */}
              {/*    <TwitterIcon /> */}
              {/*  </a> */}
              {/* </li> */}
              <li>
                <a href={instagram} target="_blank" rel="noopener noreferrer">
                  <InstagramIcon />
                </a>
              </li>
            </StyledFooter>
          </StyledContent>
        </StyledContentWrapper>
      </StyledContainer>
    </RawModal>
  )
}

export default SiteMenu
