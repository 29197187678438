import styled from "styled-components"
import theme from "../../theme"
import { DESKTOP_BREAKPOINT } from "../../responsive"

const MediumPlusHeading = styled.h3`
  font-size: ${theme.fontSizes.mediumPlus}px;
  line-height: ${theme.lineHeights.mediumPlus / theme.fontSizes.mediumPlus};
  font-weight: 700;
`

export default MediumPlusHeading

export const ResponsiveMediumPlusHeading = styled(MediumPlusHeading)`
  ${DESKTOP_BREAKPOINT} {
    font-size: ${theme.fontSizes.large}px;
    line-height: 32px;
  }
`
