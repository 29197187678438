import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import theme from "../../../../ui/theme"
import MaxWidthContent, {
  ResponsiveMaxWidthContent,
} from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import MediumHeading from "../../../../ui/typography/MediumHeading/MediumHeading"
import BodyText from "../../../../ui/typography/BodyText/BodyText"
import CoachFAQS from "../CoachFAQS/CoachFAQS"
import AboutYouspired, {
  StyledExploreButton,
  YouspiredFooterCTA,
} from "../../../../components/AboutYouspired/AboutYouspired"
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  StyledDesktopOnly,
  StyledMobileOnly,
} from "../../../../ui/responsive"
import {
  ActionButtonTheme,
  ShorterActionButton,
} from "../../../../ui/buttons/ActionButton/ActionButton"
import { ROUTES } from "../../../../navigation/routes"
import { ICMSCoach } from "../../../../data/cms/coach"

const StyledContainer = styled.section<{
  isPlaceholder: boolean
}>`
  background: ${theme.colors.darkGrey};
  color: ${theme.colors.white};
  margin-top: ${props => props.isPlaceholder ? "0px" : "50px"};
  padding-top: 47px;

  ${DESKTOP_BREAKPOINT} {
    margin-top: ${props => props.isPlaceholder ? "0px" : "128px"};
    padding-top: 108px;
  }
 
`

const StyledMediumHeading = styled(MediumHeading)`
  color: ${theme.colors.primary};
  margin-bottom: ${theme.spacing.small}px;
`

const StyledSection = styled.section`
  ${MOBILE_BREAKPOINT} {
    &:not(:first-child) {
      margin-top: ${theme.spacing.mediumPlusLess}px;
    }
  }

  ${DESKTOP_BREAKPOINT} {
    grid-column: span 2;
    padding-right: 20px;
  }
`

export const StyledPlainList = styled.ul`
  line-height: 28px;

  li {
    border-bottom: 1px solid ${theme.colors.lightGrey};
    min-height: ${theme.dimensions.infoListItemHeight}px;
    box-sizing: content-box;
    display: flex;
    align-items: center;

    &:first-child {
      border-top: 1px solid ${theme.colors.lightGrey};
    }
  }
`

const StyledInfoContainer = styled(ResponsiveMaxWidthContent)`
  ${DESKTOP_BREAKPOINT} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: ${theme.grid.desktopGutterWidth}px;
  }
`

const StyledCTAContainer = styled.div`
  padding-top: 136px;
  //display: flex;
  //justify-content: center;
  //
  //a,
  //button {
  //  width: auto;
  //}
`

const CoachFurtherDetailsSection: React.FC<{
  synopsisTitle: string
  synopsis: string
  fociTitle: string
  foci: string[]
  faqs: ICMSCoach["faqs"]
  placeholder?: boolean
}> = ({ synopsisTitle, synopsis, fociTitle, foci, faqs, placeholder = false}) => (
  <StyledContainer isPlaceholder={placeholder}>
    <StyledInfoContainer>
      <StyledSection>
        <StyledMediumHeading as="h3">{synopsisTitle}</StyledMediumHeading>
        <BodyText>{synopsis}</BodyText>
      </StyledSection>
      <StyledSection>
        <StyledMediumHeading as="h3">{fociTitle}</StyledMediumHeading>
        <StyledPlainList>
          {foci.map(focus => {
            return <li key={focus}>{focus}</li>
          })}
        </StyledPlainList>
      </StyledSection>
      <StyledSection>
        <CoachFAQS faqs={faqs} />
      </StyledSection>
    </StyledInfoContainer>
    <StyledMobileOnly>
      <MaxWidthContent>
        <StyledSection>
          <AboutYouspired />
        </StyledSection>
      </MaxWidthContent>
    </StyledMobileOnly>
    <StyledDesktopOnly>
      <StyledCTAContainer>
        {/*  <StyledExploreButton */}
        {/*    as={Link} */}
        {/*    to={ROUTES.discover.path} */}
        {/*    theme={ActionButtonTheme.SECONDARY} */}
        {/*  > */}
        {/*    Explore Youspired */}
        {/*  </StyledExploreButton> */}
        <YouspiredFooterCTA />
      </StyledCTAContainer>
    </StyledDesktopOnly>
  </StyledContainer>
)

export default CoachFurtherDetailsSection
