import styled, { css } from "styled-components"

export const BREAKPOINTS = {
  mobile: 770,
}

export const minQuery = (breakpoint: number) =>
  `@media (min-width: ${breakpoint}px)`

export const maxQuery = (breakpoint: number) =>
  `@media (max-width: ${breakpoint}px)`

export const MEDIA_BREAKPOINTS = {
  maxMobile: maxQuery(BREAKPOINTS.mobile),
  minDesktop: minQuery(BREAKPOINTS.mobile + 1),
}

export const isDesktopSize = (): boolean => {
  if (typeof window === `undefined`) {
    return false
  }
  return window.innerWidth >= BREAKPOINTS.mobile + 1
}

export const MOBILE_BREAKPOINT = MEDIA_BREAKPOINTS.maxMobile

export const DESKTOP_BREAKPOINT = MEDIA_BREAKPOINTS.minDesktop

export const hideOnMobileCss = css`
  ${MOBILE_BREAKPOINT} {
    display: none;
  }
`

export const hideOnDesktopCss = css`
  ${DESKTOP_BREAKPOINT} {
    display: none;
  }
`

export const showOnMobileCss = css`
  ${hideOnDesktopCss}
`

export const showOnDesktopCss = css`
  ${hideOnMobileCss}
`

export const StyledMobileOnly = styled.div`
  ${showOnMobileCss};
`

export const StyledDesktopOnly = styled.div`
  ${showOnDesktopCss};
`
