import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import theme from "../../ui/theme"
import LargeHeading from "../../ui/typography/LargeHeading/LargeHeading"
import ActionButton from "../../ui/buttons/ActionButton/ActionButton"
import { ROUTES } from "../../navigation/routes"
import { useModalsHandlerContext } from "../ModalsHandler/ModalsHandler"
import YouspiredMark from "../../ui/icons/YouspiredMark/YouspiredMark"
import { useAuthHandlerContext } from "../../auth/components/AuthHandler/AuthHandler"
import PopupPrompt from "./components/PopupPrompt/PopupPrompt"
import PriceText from "../../ui/components/PriceText/PriceText"

const StyledCTAContainer = styled.div`
  margin: 24px 0;
`

const StyledSignInContainer = styled.div`
  font-size: ${theme.fontSizes.smallish}px;
  line-height: 18px;

  a {
    text-decoration: underline;
    margin-left: 13px;
  }
`

const StyledLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 44.5px;

  svg {
    width: 65px;
    height: auto;
  }
`

const SignUpPrompt: React.FC<{
  destination: string
  disableClose?: boolean
  includeLogo?: boolean
  opaque?: boolean
  name?: string
  coachId?: string
  coachSlug?: string
  coachPrice?: string
}> = ({
  disableClose = false,
  includeLogo = false,
  opaque = false,
  name = "",
  destination = "",
  coachSlug = "",
  coachPrice = "",
}) => {
  // console.log("coachSlug", coachSlug)

  const { authenticated } = useAuthHandlerContext()
  const { setSignUpPrompt } = useModalsHandlerContext()
  const onClose = () => {
    if (!disableClose) {
      setSignUpPrompt(null)
    }
  }
  // eslint-disable-next-line no-nested-ternary
  const signUpDestination = coachSlug
    ? authenticated
      ? ROUTES.coachSignupBilling.paramPath(coachSlug, destination)
      : ROUTES.coachSignup.paramPath(coachSlug, destination)
    : ROUTES.signUp.paramPath(destination)
  return (
    <PopupPrompt
      onClose={onClose}
      opaque={opaque}
      disableClose={disableClose}
      footer={
        includeLogo && (
          <StyledLogoWrapper>
            <YouspiredMark />
          </StyledLogoWrapper>
        )
      }
    >
      {authenticated ? (
        <>
          <LargeHeading>Subscribe to view all of {name} sessions</LargeHeading>
          <StyledCTAContainer>
            <ActionButton as={Link} to={signUpDestination} onClick={onClose}>
              <PriceText
                price={Number(coachPrice)}
                prefix="Subscribe - $"
                suffix=" USD / month"
              />
            </ActionButton>
          </StyledCTAContainer>
        </>
      ) : (
        <>
          <LargeHeading>Sign up to view all of {name} sessions</LargeHeading>
          <StyledCTAContainer>
            <ActionButton as={Link} to={signUpDestination} onClick={onClose}>
              Sign Up
            </ActionButton>
          </StyledCTAContainer>
          <StyledSignInContainer>
            <span>Already have an account?</span>
            <Link to={ROUTES.signIn.paramPath(destination)} onClick={onClose}>
              Sign In
            </Link>
          </StyledSignInContainer>
        </>
      )}
    </PopupPrompt>
  )
}

export default SignUpPrompt
