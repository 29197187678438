import { css } from "styled-components"

const MOBILE_DESIGN_HEIGHT = 736

export const getResponsiveMobileHeight = (height: number): string => {
  return `${(height / MOBILE_DESIGN_HEIGHT) * 100}vh`
}

export const retinaMediaQuery = `@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2),
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx)`

export const placeholderCss = (styles: string) => {
  return css`
    ::-webkit-input-placeholder {
      ${styles}
    }
    :-moz-placeholder {
      ${styles}
    }
    ::-moz-placeholder {
      ${styles}
    }
    :-ms-input-placeholder {
      ${styles}
    }
  `
}
