import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { RouteComponentProps } from "@reach/router"
import { Link, navigate } from "gatsby"
import firebase from "firebase/app"
import ls from "local-storage"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import queryString from "querystring"
import { StyledStepHeading } from "../../../InfoStep/InfoStep"
import ActionButton, {
  ButtonLoadingIndicator,
  purpleLoadingCss,
  ShorterActionButton,
} from "../../../../../../ui/buttons/ActionButton/ActionButton"
import theme from "../../../../../../ui/theme"
import { ROUTES } from "../../../../../../navigation/routes"
import CreateStep from "../../CreateStep"
import { useSignUpBasePath } from "../../../../components/SignUpContextHandler/SignUpContextHandler"
import {
  firebaseAppleSignInWithPopup,
  firebaseAppleSignInWithRedirect,
  firebaseFacebookSignInWithPopup,
  shouldSignInWithPopup,
} from "../../../../../../firebase/auth"
import { useIsUserAuthenticated } from "../../../../../../auth/components/AuthHandler/AuthHandler"
import AppleIcon from "../../../../../../ui/icons/AppleIcon/AppleIcon"
import {
  mixpanelAlias,
  mixpanelEventCreateAccount,
  mixpanelSetProfileSignupDetails,
} from "../../../../../../analytics/mixpanel"
import { gaEventAccountSignUp } from "../../../../../../analytics/google"
import FirebaseErrorMessage from "./components/FirebaseErrorMessage/FirebaseErrorMessage"

const FB_KEY = "SIGNING_IN_VIA_FB"
const APPLE_KEY = "SIGNING_IN_VIA_APPLE"

export const storeSigningInViaApple = () => {
  ls.set(APPLE_KEY, true) // todo - set expiration
}

export const getIsSigningInViaApple = (): boolean => {
  const signingIn = ls.get(APPLE_KEY)
  return !!signingIn
}

export const storeSigningInViaFb = () => {
  ls.set(FB_KEY, true) // todo - set expiration
}

export const getIsSigningInViaFb = (): boolean => {
  const signingIn = ls.get(FB_KEY)
  return !!signingIn
}

export const clearSigningInStorage = () => {
  ls.remove(FB_KEY)
  ls.remove(APPLE_KEY)
}

const StyledEmailButton = styled(ActionButton)`
  background: ${theme.colors.secondary};
`

export const StyledDivider = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-size: ${theme.fontSizes.smallish}px;
  line-height: 17px;
  position: relative;
  margin: 32px 0;

  &::before,
  &::after {
    position: absolute;
    content: "";
    top: 50%;
    width: calc(50% - 31.5px);
    height: 1px;
    background: #3b3b3b;
    margin-top: -1px;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
`

export const StyledOptionWrapper = styled.div`
  &:not(:first-child) {
    margin-top: ${theme.spacing.small}px;
  }
`

const StyledOptionsContainer = styled.div`
  margin-top: 15.5px;
`
export const StyledFacebookButton = styled(ShorterActionButton)<{
  loading: boolean
}>`
  border: 1px solid ${theme.colors.secondary};
  background: none;
  color: ${theme.colors.white};
  ${props => (props.loading ? purpleLoadingCss : "")};
`

const loadingCss = css`
  svg {
    visibility: hidden;
  }
`

export const StyledAppleButton = styled(ShorterActionButton)`
  background: ${theme.colors.white};
  color: ${theme.colors.darkGrey};
  ${props => (props.loading ? purpleLoadingCss : "")};
  ${props => (props.loading ? loadingCss : "")};
`

const StyledSwitchContainer = styled.div`
  color: ${theme.colors.lightGrey};
  //font-size: ${theme.fontSizes.smallish}px;
  margin-top: ${theme.spacing.medium}px;
  text-align: center;

  a {
    span {
      color: ${theme.colors.white};
      text-decoration: underline;
      margin-left: 4px;
      display: inline-block;
    }
  }
`

export const getDestinationParam = (): string => {
  if (typeof window === `undefined`) {
    return ""
  }
  const parsed = queryString.parse(window.location.search)
  let paramDestination = "/"
  if (parsed.d) {
    paramDestination = parsed.d.toString()
  } else if (parsed["?d"]) {
    paramDestination = parsed["?d"].toString()
  } else {
    paramDestination = ROUTES.discover.path
  }
  return paramDestination
}

interface Props extends RouteComponentProps {
  firstStep?: boolean
  noCoach?: boolean
  coachSlug?: string
}

const SelectSignUpOptionView: React.FC<Props> = ({
  firstStep = false,
  noCoach = true,
  coachSlug = "",
}) => {
  const mixpanel = useMixpanel()
  const authenticated = useIsUserAuthenticated()
  const basePath = useSignUpBasePath()
  const [signingInViaFb, setSigningInViaFb] = useState(false)
  const [fbError, setFbError] = useState<Error>()
  const [appleError, setAppleError] = useState<Error>()
  const [signingInViaApple, setSigningInViaApple] = useState(false)
  const [destination, setDestination] = useState(getDestinationParam())

  useEffect(() => {
    // set twice to cover both server and client
    setDestination(getDestinationParam())
  }, [])

  // have to trigger this on client to ensure page is updated
  useEffect(() => {
    setSigningInViaFb(getIsSigningInViaFb())
    setSigningInViaApple(getIsSigningInViaApple())
  }, [])

  useEffect(() => {
    if (authenticated) {
      navigate(`${basePath}${ROUTES.signUpAccount.subPath}`)
    }
  }, [authenticated])

  const handleFacebookAuth = () => {
    setSigningInViaFb(true)
    firebaseFacebookSignInWithPopup()
      .catch(error => {
        console.error(error)
        Sentry.captureMessage(
          "Something went wrong when signing in via Facebook"
        )
        Sentry.captureException(error)
        setFbError(error)
      })
      .finally(() => {
        setSigningInViaFb(false)
      })
    // storeSigningInViaFb()
    // setTimeout(() => {
    //   firebaseFacebookSignInWithRedirect()
    // }) // timeout ensures local storage is set before redirect
  }

  const handleAppleAuth = () => {
    setSigningInViaApple(true)
    const signInWithPopup = shouldSignInWithPopup()
    if (signInWithPopup) {
      firebaseAppleSignInWithPopup()
        .catch(error => {
          console.error(error)
          Sentry.captureMessage(
            "Something went wrong when signing in via Apple"
          )
          Sentry.captureException(error)
          setAppleError(error)
        })
        .finally(() => {
          setSigningInViaApple(false)
        })
    } else {
      storeSigningInViaApple()
      setTimeout(() => {
        firebaseAppleSignInWithRedirect()
      }) // timeout ensures local storage is set before redirect
    }
  }

  useEffect(() => {
    const fbSignIn = getIsSigningInViaFb()
    firebase
      .auth()
      .getRedirectResult()
      .then(result => {
        if (result && result.user) {
          mixpanelAlias(mixpanel, result.user.uid)
          if (signingInViaFb) {
            mixpanelSetProfileSignupDetails(
              mixpanel,
              result.user.uid,
              "facebook"
            )
            gaEventAccountSignUp("Facebook")
          } else if (signingInViaApple) {
            mixpanelSetProfileSignupDetails(mixpanel, result.user.uid, "apple")
            gaEventAccountSignUp("Apple")
          }
          mixpanelEventCreateAccount(mixpanel)
        }
      })
      .catch(error => {
        // // Handle Errors here.
        // const errorCode = error.code
        // const errorMessage = error.message
        // // The email of the user's account used.
        // const { email } = error
        // // The firebase.auth.AuthCredential type that was used.
        // const { credential } = error
        console.error(error)
        Sentry.captureMessage(
          "Something went wrong when getting firebase auth redirect result"
        )
        Sentry.captureException(error)
        try {
          const { credential } = error
          if (fbSignIn || credential.signInMethod === "facebook.com") {
            setFbError(error)
          } else {
            setAppleError(error)
          }
        } catch (tryError) {
          console.error(tryError)
        }
      })
      .finally(() => {
        // console.log("done")
        clearSigningInStorage()
        setSigningInViaFb(false)
        setSigningInViaApple(false)
      })
  }, [])

  return (
    <CreateStep
      back={firstStep ? "" : `${basePath}${ROUTES.signUp.subPath}`}
      firstStep={firstStep}
    >
      <section>
        <StyledStepHeading as="h2">Create Account</StyledStepHeading>
        <StyledOptionsContainer>
          <StyledOptionWrapper>
            <StyledFacebookButton
              onClick={handleFacebookAuth}
              loading={signingInViaFb}
            >
              {fbError
                ? "Facebook sign in failed! Try again"
                : "Sign up with Facebook"}
              {signingInViaFb && <ButtonLoadingIndicator />}
            </StyledFacebookButton>
            {fbError && <FirebaseErrorMessage error={fbError} />}
          </StyledOptionWrapper>
          <StyledOptionWrapper>
            <StyledAppleButton
              onClick={handleAppleAuth}
              loading={signingInViaApple}
            >
              <AppleIcon />
              {appleError
                ? "Apple sign in failed! Try again"
                : "Sign up with Apple"}
              {signingInViaApple && <ButtonLoadingIndicator />}
            </StyledAppleButton>
            {appleError && <FirebaseErrorMessage error={appleError} />}
          </StyledOptionWrapper>
        </StyledOptionsContainer>
        <StyledDivider>or</StyledDivider>
        <div>
          <StyledEmailButton
            as={Link}
            to={`${basePath}${ROUTES.signUpCreateEmail.subPath}`}
          >
            Sign up with Email
          </StyledEmailButton>
        </div>
        <StyledSwitchContainer>
          <Link
            to={ROUTES.signIn.paramPath(
              noCoach
                ? destination
                : ROUTES.coachSignupBilling.paramPath(coachSlug)
            )}
          >
            Switch to <span>Sign In</span>
          </Link>
        </StyledSwitchContainer>
      </section>
    </CreateStep>
  )
}

export default SelectSignUpOptionView
