import React, { useContext, useEffect } from "react"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import { mixpanelTrackPageView } from "../../mixpanel"

export interface PageLocationContextState {
  coachEntryLocation: string
}

export const PageLocationContext = React.createContext<
  PageLocationContextState
>({
  coachEntryLocation: "",
})

export const useCoachEntryLocation = (): string => {
  return useContext(PageLocationContext).coachEntryLocation
}

const PageLocation: React.FC<{
  coachEntryLocation?: string
  pageName: string
}> = ({ coachEntryLocation = "", pageName, children }) => {
  const mixpanel = useMixpanel()
  useEffect(() => {
    mixpanelTrackPageView(mixpanel, pageName)
  }, [pageName])

  return (
    <PageLocationContext.Provider value={{ coachEntryLocation }}>
      {children}
    </PageLocationContext.Provider>
  )
}

export default PageLocation
