import ReactGA from "react-ga"

ReactGA.initialize(process.env.GATSBY_GOOGLE_ANALYTICS_ID || "", {
  titleCase: false,
})

export const gaSetUser = (uid: string) => {
  ReactGA.set({
    userId: uid,
  })
}

enum EventCategory {
  Subscriptions = "Subscriptions",
  Videos = "Videos",
  Account = "Account",
  Search = "Search",
}

interface EventValue {
  action: string
  category?: string
  label?: string
  value?: number
}

const sendSubscriptionsEvent = (values: EventValue) => {
  ReactGA.event({
    category: EventCategory.Subscriptions,
    ...values,
  })
}

const sendVideosEvent = (values: EventValue) => {
  ReactGA.event({
    category: EventCategory.Videos,
    ...values,
  })
}

const sendAccountEvent = (values: EventValue) => {
  ReactGA.event({
    category: EventCategory.Account,
    ...values,
  })
}

const sendSearchEvent = (values: EventValue) => {
  ReactGA.event({
    category: EventCategory.Search,
    ...values,
  })
}

export const gaEventSubscriptionsSubscribe = (
  coachName: string,
  subscriptionPrice: number
) => {
  sendSubscriptionsEvent({
    action: "Subscribe",
    label: coachName,
    value: subscriptionPrice,
  })
}

export const gaEventSubscriptionsCancelSubscription = (
  coachName: string,
  subscriptionPrice: number
) => {
  sendSubscriptionsEvent({
    action: "Cancel Subscription",
    label: coachName,
    value: subscriptionPrice,
  })
}

export const gaEventVideosWatchTrailer = (
  coachName: string,
  durationWatched: number
) => {
  sendVideosEvent({
    action: "Watch Trailer",
    label: coachName,
    value: durationWatched,
  })
}

const getVideoLabel = (videoTitle: string, videoId: string): string => {
  return `${videoTitle} - ${videoId}`
}

export const gaEventVideosStartVideo = (
  videoTitle: string,
  videoId: string,
  durationWatched: number
) => {
  sendVideosEvent({
    action: "Start Video",
    label: getVideoLabel(videoTitle, videoId),
    value: durationWatched,
  })
}

export const gaEventVideosWatchVideoHalf = (
  videoTitle: string,
  videoId: string,
  durationWatched: number
) => {
  sendVideosEvent({
    action: "Watch Video - 50%",
    label: getVideoLabel(videoTitle, videoId),
    value: durationWatched,
  })
}

export const gaEventVideosCoach = (coachName: string) => {
  sendVideosEvent({
    action: "Coach",
    label: coachName,
  })
}

export const gaEventVideosTopic = (topic: string) => {
  sendVideosEvent({
    action: "Topic",
    label: topic,
  })
}

export const gaEventAccountSignUp = (method: string) => {
  sendAccountEvent({
    action: "Sign Up",
    label: method,
  })
}

export const gaEventAccountSignIn = (method: string) => {
  sendAccountEvent({
    action: "Sign In",
    label: method,
  })
}

export const gaEventAccountAddShortlist = (coachName: string) => {
  sendAccountEvent({
    action: "Add Shortlist",
    label: coachName,
  })
}

export const gaEventAccountRemoveShortlist = (coachName: string) => {
  sendAccountEvent({
    action: "Remove Shortlist",
    label: coachName,
  })
}

export const gaEventSearchSearch = (term: string) => {
  sendSearchEvent({
    action: "Search",
    label: term,
  })
}
