import { graphql } from "gatsby"
import { get } from "lodash"

export interface ICMSMetaSocial {
  facebookAccountUrl: string
  instagramAccountUrl: string
  twitterAccountUrl: string
  downloadAppUrl: string
  helpUrl: string
  accountScreenApplyToCoachUrl: string
  defaultFooterCta1Label: string
  defaultFooterCta1Destination: string
  defaultFooterCta2Label: string
  defaultFooterCta2Destination: string
  menuForCoachesUrl: string
  menuApplyToCoachUrl: string
}

export const getMenuForCoachesUrl = (data: ICMSMetaSocial): string => {
  return get(data, "menuForCoachesUrl", "") || ""
}

export const getMenuApplyToCoach = (data: ICMSMetaSocial): string => {
  return get(data, "menuApplyToCoachUrl", "") || ""
}

export const getDefaultFooterCta1Label = (data: ICMSMetaSocial): string => {
  return get(data, "defaultFooterCta1Label", "") || ""
}

export const getDefaultFooterCta1Destination = (
  data: ICMSMetaSocial
): string => {
  return get(data, "defaultFooterCta1Destination", "") || ""
}

export const getDefaultFooterCta2Label = (data: ICMSMetaSocial): string => {
  return get(data, "defaultFooterCta2Label", "") || ""
}

export const getDefaultFooterCta2Destination = (
  data: ICMSMetaSocial
): string => {
  return get(data, "defaultFooterCta2Destination", "") || ""
}

export const getAccountScreenApplyToCoachUrl = (
  data: ICMSMetaSocial
): string => {
  return get(data, "accountScreenApplyToCoachUrl", "") || ""
}

export const getHelpUrl = (data: ICMSMetaSocial): string => {
  return get(data, "helpUrl", "") || ""
}

export const getFacebookUrl = (data: ICMSMetaSocial): string => {
  return get(data, "facebookAccountUrl", "") || ""
}

export const getInstagramUrl = (data: ICMSMetaSocial): string => {
  return get(data, "instagramAccountUrl", "") || ""
}

export const getTwitterUrl = (data: ICMSMetaSocial): string => {
  return get(data, "twitterAccountUrl", "") || ""
}

export const getDownloadAppUrl = (data: ICMSMetaSocial): string => {
  return get(data, "downloadAppUrl", "") || ""
}

export interface ICMSSeoTags {
  title?: string
  description?: string
  twitterCard?: string
  image?: {
    url: string
  }
}

export interface ICMSPageMeta {
  pageTitle?: string
  seoTags?: ICMSSeoTags
}

export const getSeoTagsTitle = (data: ICMSSeoTags): string => {
  return get(data, "title", "") || ""
}

export const getSeoTagsDescription = (data: ICMSSeoTags): string => {
  return get(data, "description", "") || ""
}

export const getSeoTagsTwitterCard = (data: ICMSSeoTags): string => {
  return get(data, "twitterCard", "") || ""
}

export const getSeoTagsImage = (data: ICMSSeoTags): string => {
  return get(data, "image.url", "") || ""
}

export const getSeoProps = (
  data: ICMSSeoTags
): {
  title: string
  description: string
  twitterCard: string
  image: string
} => {
  return {
    title: getSeoTagsTitle(data),
    description: getSeoTagsDescription(data),
    twitterCard: getSeoTagsTwitterCard(data),
    image: getSeoTagsImage(data),
  }
}

interface PropsResponse {
  pageTitle?: string
  title?: string
  description?: string
  twitterCard?: string
  image?: string
}

export const getSeoPageMetaProps = (
  data: ICMSPageMeta
): {
  pageTitle?: string
  title?: string
  description?: string
  twitterCard?: string
  image?: string
} => {
  let props: PropsResponse = {}
  const seoTags: ICMSSeoTags | null = get(data, "seoTags", null) || null
  if (seoTags) {
    props = {
      ...props,
      ...getSeoProps(seoTags),
    }
  }
  const pageTitle: string = get(data, "pageTitle", "") || ""
  if (pageTitle) {
    props.pageTitle = pageTitle
  }
  return props
}

export const query = graphql`
  fragment SeoTags on DatoCmsSeoField {
    title
    description
    twitterCard
    image {
      url
    }
  }

  fragment PageMeta on DatoCmsWebMetaTag {
    pageTitle
    seoTags {
      ...SeoTags
    }
  }

  fragment WebMetaSocialDev on DatoCmsWebMetaSocialDev {
    facebookAccountUrl
    instagramAccountUrl
    twitterAccountUrl
    downloadAppUrl
    helpUrl
    accountScreenApplyToCoachUrl
    defaultFooterCta1Label
    defaultFooterCta1Destination
    defaultFooterCta2Label
    defaultFooterCta2Destination
    menuForCoachesUrl
    menuApplyToCoachUrl
  }

  fragment WebMetaSocialStg on DatoCmsWebMetaSocialStg {
    facebookAccountUrl
    instagramAccountUrl
    twitterAccountUrl
    downloadAppUrl
    helpUrl
    accountScreenApplyToCoachUrl
    defaultFooterCta1Label
    defaultFooterCta1Destination
    defaultFooterCta2Label
    defaultFooterCta2Destination
    menuForCoachesUrl
    menuApplyToCoachUrl
  }

  fragment WebMetaSocial on DatoCmsWebMetaSocial {
    facebookAccountUrl
    instagramAccountUrl
    twitterAccountUrl
    downloadAppUrl
    helpUrl
    accountScreenApplyToCoachUrl
    defaultFooterCta1Label
    defaultFooterCta1Destination
    defaultFooterCta2Label
    defaultFooterCta2Destination
    menuForCoachesUrl
    menuApplyToCoachUrl
  }

  fragment WebMetaSocialPrd on DatoCmsWebMetaSocialPrd {
    facebookAccountUrl
    instagramAccountUrl
    twitterAccountUrl
    downloadAppUrl
    helpUrl
    accountScreenApplyToCoachUrl
    defaultFooterCta1Label
    defaultFooterCta1Destination
    defaultFooterCta2Label
    defaultFooterCta2Destination
    menuForCoachesUrl
    menuApplyToCoachUrl
  }
`
