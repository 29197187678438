import React, { useEffect } from "react"
import ReactModal from "react-modal"
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock"
import { css } from "../../../utils/utils"
import theme from "../../theme"

ReactModal.setAppElement("#___gatsby")

const overlayClass = css`
  z-index: ${theme.zIndexes.videoOverlay};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.4);
`

const darkerOverlayClass = css`
  ${overlayClass};
  background-color: rgba(0, 0, 0, 0.8);
`

const fullScreenOverlayClass = css`
  z-index: ${theme.zIndexes.videoOverlay};
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`

const contentClass = css`
  border: 0;
  border-radius: 0;
  padding: 0;
  width: 100%;
  outline: currentcolor none medium;
`

const RawModal: React.FC<{
  darker?: boolean
  fullScreen?: boolean
  onClose: () => void
  isOpen?: boolean
}> = ({ children, darker, onClose, fullScreen = false, isOpen = true }) => {
  const onOpen = () => {}

  const clearScroll = () => {
    document.documentElement.style.overflow = ""
    clearAllBodyScrollLocks()
  }

  useEffect(() => {
    let needToClear = false

    if (typeof document !== `undefined`) {
      if (isOpen) {
        needToClear = true
        document.documentElement.style.overflow = "hidden"
        disableBodyScroll(null)
      }
    }

    return () => {
      if (needToClear) {
        clearScroll()
      }
    }
  }, [isOpen])

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={onOpen}
      onRequestClose={onClose}
      className={contentClass}
      overlayClassName={
        // eslint-disable-next-line no-nested-ternary
        fullScreen
          ? fullScreenOverlayClass
          : darker
          ? darkerOverlayClass
          : overlayClass
      }
    >
      {children}
    </ReactModal>
  )
}

export default RawModal
