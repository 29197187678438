import ls from "local-storage"
import { get } from "lodash"
import firebase from "firebase/app"
import { firebaseApp, firebaseDatabase } from "./client"

export interface IFirestoreUserDoc {
  email: string
  shortlistedCoachIds: string[]
  subscriptions?: {
    [key: string]: number
  }
  userType: string
  username: string
  termsAccepted?: number
  stripeCustomer?: string
}

export const getFirestoreUserUsername = (user: IFirestoreUserDoc): string => {
  return get(user, "username", "") || ""
}
export const getFirestoreUserEmail = (user: IFirestoreUserDoc): string => {
  return get(user, "email", "") || ""
}

export const usersCollection = firebaseDatabase.collection("users")

export const getUserDoc = (uid: string) => {
  return usersCollection.doc(uid)
}

export const getUserSubscriptions = async (): Promise<IFirestoreUserDoc["subscriptions"]> => {
  const user = await firebase.auth().currentUser
  if (!user) return Promise.reject()
  return getUserDoc(user.uid)
    .get()
    .then(snapshot => {
      const firestoreUser:
        | IFirestoreUserDoc
        | undefined = snapshot.data() as IFirestoreUserDoc
      if (!firestoreUser) return {}
      const { subscriptions = {} } = firestoreUser
      return subscriptions
    })
}

const KEY_FIRESTORE_USER = "KEY_FIRESTORE_USER"

export const storeUser = (user: IFirestoreUserDoc) => {
  ls.set(KEY_FIRESTORE_USER, user)
}

export const clearStoredUser = () => {
  ls.remove(KEY_FIRESTORE_USER)
}

export const getStoredUser = (): IFirestoreUserDoc | null => {
  return null // todo - re-enable
  return ls.get(KEY_FIRESTORE_USER)
}

export const getFirstName = (name: string): string => {
  return name.split(" ")[0]
}

export const getLastName = (name: string): string => {
  const words = name.split(" ")
  if (words.length > 1) {
    return words[words.length - 1]
  }
  return ""
}

export const setUserTermsAccepted = () => {
  const user = firebaseApp.auth().currentUser
  if (!user) {
    return Promise.reject()
  }
  const userRef = getUserDoc(user.uid)
  return userRef.update({
    termsAccepted: firebase.firestore.FieldValue.serverTimestamp(),
  })
}

export const setUserUsername = (username: string) => {
  const user = firebaseApp.auth().currentUser
  if (!user) {
    return Promise.reject()
  }
  const userRef = getUserDoc(user.uid)
  return userRef.update({
    username,
  })
}

export const setUserUsernameAndTerms = (username: string) => {
  const user = firebaseApp.auth().currentUser
  if (!user) {
    return Promise.reject()
  }
  const userRef = getUserDoc(user.uid)
  return userRef.update({
    username,
    termsAccepted: firebase.firestore.FieldValue.serverTimestamp(),
  })
}

export const addCoachToShortlist = (coachId: string) => {
  const user = firebaseApp.auth().currentUser
  if (!user) {
    return Promise.reject()
  }
  const userRef = getUserDoc(user.uid)
  return userRef.update({
    shortlistedCoachIds: firebase.firestore.FieldValue.arrayUnion(coachId),
  })
}

export const removeCoachFromShortlist = (coachId: string) => {
  const user = firebaseApp.auth().currentUser
  if (!user) {
    return Promise.reject()
  }
  const userRef = getUserDoc(user.uid)
  return userRef.update({
    shortlistedCoachIds: firebase.firestore.FieldValue.arrayRemove(coachId),
  })
}
