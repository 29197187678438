import styled from "styled-components"
import theme from "../../theme"

const MediumHeading = styled.h3`
  font-size: ${theme.fontSizes.medium}px;
  line-height: ${theme.lineHeights.medium / theme.fontSizes.medium};
  font-weight: 700;
`

export default MediumHeading
