import React from "react"
import CurrencyFormat from "react-currency-format"

interface Props {
  price: number
  prefix?: string
  suffix?: string
}

const ispriceWithDecimals = (price: number): boolean => {
  if (!Number.isInteger(price)) {
    return true
  }
  return false
}

const PriceText: React.FC<Props> = ({
  price = 0,
  prefix = "",
  suffix = "",
}) => {
  return (
    <>
      {ispriceWithDecimals(price) ? (
        <CurrencyFormat
          value={price}
          prefix={prefix}
          suffix={suffix}
          displayType="text"
          decimalScale={2}
          fixedDecimalScale
        />
      ) : (
        <>{`${prefix}${price}${suffix}`}</>
      )}
    </>
  )
}

export default PriceText
