import React, { useState } from "react"
import styled, { css } from "styled-components"
import theme from "../../ui/theme"
import FAQIcon from "../../assets/inline-assets/faq.svg"
import BottomArrowIcon from "../../assets/inline-assets/arrowBottom.svg"

const redIconCss = css`
  path {
    stroke: ${theme.colors.primary};
  }
`

const StyledIconWrapper = styled.div<{
  redIcon: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    ${props => (props.redIcon ? redIconCss : "")};
    width: 19px;
  }
`

const openedCss = css`
  transform: scaleY(-1);
`

const StyledOpenIconWrapper = styled.div<{
  opened: boolean
}>`
  ${props => (props.opened ? openedCss : "")};

  svg {
    width: 19px;
  }
`

const StyledFAQHeader = styled.button`
  min-height: ${theme.dimensions.infoListItemHeight}px;
  display: flex;
  align-items: center;
  text-align: left;
  color: inherit;
  width: 100%;
  font: inherit;

  p {
    margin-left: 19px;
    flex: 1;
  }
`

const StyledAnswer = styled.div`
  padding-bottom: 20px;
`

const FAQ: React.FC<{
  redIcon?: boolean
  question: string
  answer: string
}> = ({ redIcon = false, question, answer }) => {
  const [opened, setOpened] = useState(false)
  return (
    <section>
      <StyledFAQHeader
        onClick={() => {
          setOpened(!opened)
        }}
      >
        <StyledIconWrapper redIcon={redIcon}>
          <FAQIcon />
        </StyledIconWrapper>
        <p>{question}</p>
        <StyledOpenIconWrapper opened={opened}>
          <BottomArrowIcon />
        </StyledOpenIconWrapper>
      </StyledFAQHeader>
      {opened && <StyledAnswer>{answer}</StyledAnswer>}
    </section>
  )
}

export default FAQ
