import { css, keyframes } from "styled-components"
import theme from "./theme"

export const minMobileHeightContentCss = css`
  min-height: calc(100vh - 110px);
`

const placeholderPulseKeyframes = keyframes`

  from {
    background-color: ${theme.colors.placeholderFaint};
  }
  
  to {
    background-color: #f4f8fc;
  }

`

export const placeholderBackgroundCss = css`
  background-color: ${theme.colors.placeholderFaint};
  animation: ${placeholderPulseKeyframes} 1000ms ease-in-out alternate-reverse
    infinite;
`

export const bgImageCss = css`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${theme.colors.placeholder};
`

export const desktopGridCss = css`
  display: grid;
  grid-template-columns: repeat(${theme.grid.desktopNumberOfColumns}, 1fr);
  grid-column-gap: ${theme.grid.desktopGutterWidth}px;
`

export const mobileGridCss = css`
  display: grid;
  grid-template-columns: repeat(${theme.grid.mobileNumberOfColumns}, 1fr);
  grid-column-gap: ${theme.grid.mobileGutterWidth}px;
`

export const imageDropShadowCss = css`
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.11);
`
