import React from "react"
import styled from "styled-components"
import SiteHeader from "../SiteHeader/SiteHeader"
import SiteFooter from "../SiteFooter/SiteFooter"
import theme from "../../ui/theme"
import { DESKTOP_BREAKPOINT } from "../../ui/responsive"

const StyledContent = styled.div`
  padding-top: ${theme.dimensions.mobileHeaderHeight}px;

  ${DESKTOP_BREAKPOINT} {
    padding-top: ${theme.dimensions.desktopHeaderHeight}px;
  }
`

const SiteLayout: React.FC<{
  hideHeaderOptions?: boolean
}> = ({ children, hideHeaderOptions = false }) => {
  return (
    <>
      <SiteHeader hideHeaderOptions={hideHeaderOptions} />
      <StyledContent>{children}</StyledContent>
      <SiteFooter />
    </>
  )
}

export default SiteLayout
