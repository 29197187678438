import React from "react"
import { get } from "lodash"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import FacebookIcon from "../../ui/icons/FacebookIcon/FacebookIcon"
import TwitterIcon from "../../ui/icons/TwitterIcon/TwitterIcon"
import InstagramIcon from "../../ui/icons/InstagramIcon/InstagramIcon"
import {
  getDownloadAppUrl,
  getFacebookUrl,
  getInstagramUrl,
  getTwitterUrl,
  ICMSMetaSocial,
} from "../../data/cms/socialMeta"
import { getEnvKey } from "../../utils/env"

const StyledContainer = styled.ul`
  display: flex;
  align-items: center;

  > li {
    &:not(:first-child) {
      margin-left: 18px;
    }
  }
`

const StyledFB = styled.div`
  svg {
    width: 17px;
    height: auto;
  }
`

const StyledTwitter = styled.div`
  svg {
    width: 18px;
    height: auto;
  }
`

const StyledInsta = styled.div`
  svg {
    width: 17px;
    height: auto;
  }
`

export const useSocialLinks = (): {
  facebook: string
  twitter: string
  instagram: string
  downloadApp: string
} => {
  const response: {
    datoCmsWebMetaSocialDev?: ICMSMetaSocial
    datoCmsWebMetaSocialStg?: ICMSMetaSocial
    datoCmsWebMetaSocial?: ICMSMetaSocial
    datoCmsWebMetaSocialPrd?: ICMSMetaSocial
  } = useStaticQuery(graphql`
    query SocialLinksQuery {
      datoCmsWebMetaSocialDev {
        ...WebMetaSocialDev
      }
      datoCmsWebMetaSocialStg {
        ...WebMetaSocialStg
      }
      datoCmsWebMetaSocialPrd {
        ...WebMetaSocialPrd
      }
      datoCmsWebMetaSocial {
        ...WebMetaSocial
      }
    }
  `)
  const dataKey = getEnvKey({
    devKey: "datoCmsWebMetaSocialDev",
    stgKey: "datoCmsWebMetaSocialStg",
    uatKey: "datoCmsWebMetaSocial",
    prdKey: "datoCmsWebMetaSocialPrd",
    defaultKey: "datoCmsWebMetaSocial",
  })
  const data: ICMSMetaSocial = get(response, `${dataKey}`)
  return {
    facebook: getFacebookUrl(data),
    twitter: getTwitterUrl(data),
    instagram: getInstagramUrl(data),
    downloadApp: getDownloadAppUrl(data),
  }
}

const SocialLinks: React.FC = () => {
  const { facebook, twitter, instagram } = useSocialLinks()
  return (
    <StyledContainer>
      <li>
        <StyledFB>
          <a href={facebook} target="_blank" rel="noopener noreferrer">
            <FacebookIcon />
          </a>
        </StyledFB>
      </li>
      {/* <li> */}
      {/*  <StyledTwitter> */}
      {/*    <a href={twitter} target="_blank" rel="noopener noreferrer"> */}
      {/*      <TwitterIcon /> */}
      {/*    </a> */}
      {/*  </StyledTwitter> */}
      {/* </li> */}
      <li>
        <StyledInsta>
          <a href={instagram} target="_blank" rel="noopener noreferrer">
            <InstagramIcon />
          </a>
        </StyledInsta>
      </li>
    </StyledContainer>
  )
}

export default SocialLinks
