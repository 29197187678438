export const mixpanelAlias = (mixpanel: any, uid: string) => {
  mixpanel.alias(uid)
}

export const mixpanelIdentify = (mixpanel: any, uid: string) => {
  mixpanel.identify(uid)
}

export const mixpanelReset = (mixpanel: any) => {
  mixpanel.reset()
}

export const mixpanelSetProfileSignupDetails = (
  mixpanel: any,
  uid: string,
  method: string
) => {
  mixpanel.people.set({
    signupDate: Date.now().toString(),
    signupMethod: method,
  })
  mixpanelIdentify(mixpanel, uid)
}

export const mixpanelTrackPageView = (mixpanel: any, pageName: string) => {
  mixpanel.register({
    pageName,
    platform: "web",
  })
  mixpanel.track("pageView")
}

export const mixpanelRegisterProfileSuperProperties = (
  mixpanel: any,
  uid: string,
  email: string,
  username: string
) => {
  const details: {
    [key: string]: string
  } = {
    platform: "web",
  }
  if (email) {
    details.email = email
  }
  if (username) {
    details.username = username
  }
  mixpanel.register(details)
  mixpanelIdentify(mixpanel, uid)
}

export const mixpanelSetProfileSubscriptionProperties = (
  mixpanel: any,
  uid: string,
  subscribedCoaches: string[]
) => {
  mixpanel.people.set({
    numSubscribed: subscribedCoaches.length,
    subscriptions: subscribedCoaches,
  })
  mixpanelIdentify(mixpanel, uid)
}

export const mixpanelSetProfileDetails = (
  mixpanel: any,
  uid: string,
  {
    username = "",
    email = "",
    avatar = "",
  }: {
    username?: string
    email?: string
    avatar?: string
  }
) => {
  if (username) {
    mixpanel.people.set({ $username: username })
  }
  if (email) {
    mixpanel.people.set({ $email: email })
  }
  if (avatar) {
    mixpanel.people.set({ $avatar: avatar })
  }
  mixpanelRegisterProfileSuperProperties(mixpanel, uid, email, username)
  mixpanelIdentify(mixpanel, uid)
}

export const mixpanelEventSearch = (
  mixpanel: any,
  searchTerm: string,
  numberOfResults: number,
  uid: string
) => {
  mixpanel.track("search", {
    searchTerm,
    numResults: numberOfResults,
    termLength: searchTerm.length,
  })
  mixpanel.people.set({
    lastSearch: Date.now().toString(),
  })
  mixpanel.people.increment("numSearches")
  if (uid) {
    mixpanelIdentify(mixpanel, uid)
  }
}

export const mixpanelEventWatchVideoHalf = (
  mixpanel: any,
  coachName: string,
  videoTitle: string,
  videoId: string,
  topic: string,
  savedDate: string
) => {
  mixpanel.track("watch50PercVideo", {
    coach: coachName,
    title: videoTitle,
    videoID: videoId,
    topic,
    date: savedDate,
  })
}

export const mixpanelEventEndVideo = (
  mixpanel: any,
  coachName: string,
  videoTitle: string,
  videoId: string,
  topic: string,
  savedDate: string
) => {
  mixpanel.track("endVideo", {
    coach: coachName,
    title: videoTitle,
    videoID: videoId,
    topic,
    date: savedDate,
  })
}

export const mixpanelEventStartVideo = (
  mixpanel: any,
  coachName: string,
  videoTitle: string,
  videoId: string,
  topic: string,
  savedDate: string
) => {
  mixpanel.track("startVideo", {
    coach: coachName,
    title: videoTitle,
    videoID: videoId,
    topic,
    date: savedDate,
  })
}

export const mixpanelEventRemoveCoach = (
  mixpanel: any,
  coachName: string,
  isSubscribed: boolean
) => {
  mixpanel.track("removeCoach", {
    coachName,
    subscribed: isSubscribed,
  })
}

export const mixpanelEventCancelSubscription = (
  mixpanel: any,
  coachName: string
) => {
  mixpanel.track("cancelSubscription", {
    coachName,
  })
}

export const mixpanelEventAddSubscription = (
  mixpanel: any,
  coachName: string
) => {
  mixpanel.track("addSubscription", {
    coachName,
  })
}

export const mixpanelEventEditProfile = (mixpanel: any) => {
  mixpanel.track("editProfile")
}

export const mixpanelEventSignIn = (mixpanel: any, method: string) => {
  mixpanel.track("signIn", {
    signInMethod: method,
  })
}

export const mixpanelEventCreateAccount = (mixpanel: any) => {
  mixpanel.track("createAccount")
}

export const mixpanelEventViewCoach = (
  mixpanel: any,
  coachName: string,
  entryPoint: string,
  isSubscribed: boolean
) => {
  mixpanel.track("viewCoach", {
    coach: coachName,
    entryPoint,
    subscribed: isSubscribed,
  })
}

export const mixpanelEventWatchTrailer = (
  mixpanel: any,
  coachName: string,
  isSubscribed: boolean,
  videoUrl: string
) => {
  mixpanel.track("watchTrailer", {
    coach: coachName,
    videoURL: videoUrl,
    subscribed: isSubscribed,
  })
}
