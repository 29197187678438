import { useEffect, useState } from "react"
import { useAuthHandlerContext } from "./AuthHandler"
import { fetchCoaches, IFirestoreCoachDoc } from "../../../firebase/coach"
import { getUserSubscriptions, IFirestoreUserDoc } from "../../../firebase/user"

const fbClientAccessTokenUrlParam = `access_token=${process.env.GATSBY_FACEBOOK_APP_ID}|${process.env.GATSBY_FACEBOOK_CLIENT_ACCESS_TOKEN}`

export const useUserInfo = (): {
  email: string
  username: string
  name: string
  image: string
} => {
  const { fbDatabaseUser, fbUser } = useAuthHandlerContext()
  const image = fbUser?.photoURL || ""
  const isFbImage = /^https:\/\/graph\.facebook\.com\/[^/]*\/picture$/.test(
    image
  )
  const formattedImage = isFbImage
    ? `${image}?height=400&${fbClientAccessTokenUrlParam}`
    : image
  return {
    email: fbDatabaseUser?.email || "",
    username: fbDatabaseUser?.username || "",
    name: fbUser?.displayName || "",
    image: formattedImage,
  }
}

export const useIsStripeCustomer = (): boolean => {
  const { fbDatabaseUser } = useAuthHandlerContext()
  return fbDatabaseUser ? !!fbDatabaseUser.stripeCustomer : false
}

export const useHasUserAcceptedTerms = (): boolean => {
  const { fbDatabaseUser } = useAuthHandlerContext()
  return fbDatabaseUser ? !!fbDatabaseUser.termsAccepted : false
}

export const useUserShortlistedCoachesIds = (): string[] => {
  const { fbDatabaseUser } = useAuthHandlerContext()
  return fbDatabaseUser && !!fbDatabaseUser.shortlistedCoachIds
    ? fbDatabaseUser.shortlistedCoachIds
    : []
}

export const useUserShortlistedCoaches = (): [
  IFirestoreCoachDoc[],
  boolean,
  boolean
] => {
  const [loading, setLoading] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [coaches, setCoaches] = useState<IFirestoreCoachDoc[]>([])
  const shortlistedCoaches = useUserShortlistedCoachesIds()
  useEffect(() => {
    setLoading(true)
    fetchCoaches(shortlistedCoaches)
      .then(response => {
        setLoaded(true)
        setCoaches(response as IFirestoreCoachDoc[])
      })
      .finally(() => {
        setLoading(false)
      })
  }, [shortlistedCoaches.join(",")])
  return [coaches, loaded, loading]
}

export const useIsCoachShortlisted = (coachId: string): boolean => {
  const coachIds = useUserShortlistedCoachesIds()
  return coachIds.includes(coachId)
}

export const useSubscribedCoaches = (): string[] => {
  const { fbUser, fbDatabaseUser } = useAuthHandlerContext()
  const subscriptions =
    fbDatabaseUser && fbDatabaseUser.subscriptions
      ? fbDatabaseUser.subscriptions
      : {}
  const uid = fbUser ? fbUser.uid : ""
  // return uid in list of subscriptions in case the user is a coach
  return Object.keys(subscriptions)
    .filter(subscriptionKey => {
      return new Date() <= new Date(subscriptions[subscriptionKey])
    })
    .concat([uid])
}

export const useTriggerRerenderOnClient = () => {
  const [clientLoaded, setClientLoaded] = useState(false)
  useEffect(() => {
    setClientLoaded(true)
  })
  return clientLoaded
}

export const useIsCoachSubscribedTo = (
  coachId: string
): [boolean, boolean, boolean, () => Promise<boolean>] => {
  const subscribedCoaches = useSubscribedCoaches()
  const coachIsInSubscribedCoaches = subscribedCoaches.includes(coachId)
  const [subscribedOnInit] = useState(coachIsInSubscribedCoaches)
  const [checking, setChecking] = useState(!subscribedOnInit)
  const [checked, setChecked] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(subscribedOnInit)

  const checkIfSubscribed = (): Promise<boolean> => {
    setChecking(true)

    // console.log("checkIfSubscribed")

    return getUserSubscriptions()
      .then((subscriptions: IFirestoreUserDoc["subscriptions"]) => {
        const isThereMatchingSubscription = subscriptions
          ? Object.keys(subscriptions).includes(coachId)
          : false
        const subscribed =
          isThereMatchingSubscription && subscriptions
            ? new Date() <= new Date(subscriptions[coachId])
            : false
        setIsSubscribed(subscribed)
        return subscribed
      })
      .finally(() => {
        setChecked(true)
        setChecking(false)
      })
  }

  useEffect(() => {
    // console.log("subscribedOnInit", subscribedOnInit)
    if (!subscribedOnInit) {
      // todo - don't check if not authenticated...
      checkIfSubscribed()
    }
  }, [subscribedOnInit])
  const subscribed = coachIsInSubscribedCoaches ? true : isSubscribed
  // const subscribed = true

  return [subscribed, checking, checked, checkIfSubscribed]
}
