import styled, { css, keyframes } from "styled-components"
import React from "react"
import theme from "../../theme"

export enum ActionButtonTheme {
  DEFAULT = "DEFAULT",
  SECONDARY = "SECONDARY",
  DARK_OUTLINE = "DARK_OUTLINE",
  OUTLINE = "OUTLINE",
  WHITE = "WHITE",
  DARK = "DARK",
}

const whiteThemeCss = css`
  background: ${theme.colors.white};
  color: ${theme.colors.black};
`

const defaultThemeCss = css`
  background: ${theme.colors.primary};
`

const secondaryThemeCss = css`
  background: ${theme.colors.secondary};
`

const outlineThemeCss = css`
  background: none;
  border: 1px solid white;
  line-height: ${(theme.lineHeights.smallishPlus - 2) /
    theme.fontSizes.default};
`

const darkOutlineThemeCss = css`
  background: none;
  border: 1px solid #d4d4d4;
  color: ${theme.colors.black};
`

const darkThemeCss = css`
  background: ${theme.colors.black};
  color: ${theme.colors.white};
`

const getThemeCss = (buttonTheme?: ActionButtonTheme) => {
  if (buttonTheme) {
    if (buttonTheme === ActionButtonTheme.SECONDARY) {
      return secondaryThemeCss
    }
    if (buttonTheme === ActionButtonTheme.OUTLINE) {
      return outlineThemeCss
    }
    if (buttonTheme === ActionButtonTheme.DARK_OUTLINE) {
      return darkOutlineThemeCss
    }
    if (buttonTheme === ActionButtonTheme.WHITE) {
      return whiteThemeCss
    }
    if (buttonTheme === ActionButtonTheme.DARK) {
      return darkThemeCss
    }
  }
  return defaultThemeCss
}

const disabledCss = css`
  opacity: 0.5;
  cursor: default;
`

const loadingCss = css`
  & {
    color: transparent;
  }

  > .loading {
    color: ${props =>
      props.theme === ActionButtonTheme.WHITE
        ? theme.colors.black
        : theme.colors.white};
  }
`

export const purpleLoadingCss = css`
  background: ${theme.colors.secondary};
  border-color: ${theme.colors.secondary};
`

const ActionButton = styled.button<{
  theme?: ActionButtonTheme
  loading?: boolean
  disabled?: boolean
}>`
  cursor: pointer;
  color: ${theme.colors.white};
  padding-top: 18.5px;
  padding-bottom: 18.5px;
  display: block;
  width: 100%;
  border-radius: ${theme.borderRadius.common}px;
  font-size: ${theme.fontSizes.default}px;
  line-height: ${theme.lineHeights.smallishPlus / theme.fontSizes.default};
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  position: relative;
  ${props => getThemeCss(props.theme)};
  ${props => (props.disabled ? disabledCss : "")};
  ${props => (props.loading ? loadingCss : "")};
`

export default ActionButton

export const SmallerActionButton = styled(ActionButton)`
  font-size: 14px;
  line-height: 16px;
  padding: 15.5px;
`

export const ShorterActionButton = styled(ActionButton)`
  padding-top: 12.5px;
  padding-bottom: 12.5px;
`

const StyledButtonLoadingIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const spinKeyframes = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const StyledSpinner = styled.div<{
  dark?: boolean
}>`
  border: 2px solid transparent;
  border-top: 2px solid ${props => (props.dark ? "black" : "white")};
  border-right: 2px solid ${props => (props.dark ? "black" : "white")};
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: ${spinKeyframes} 1250ms linear infinite;
`

export const ButtonLoadingIndicator: React.FC = ({ children }) => {
  return (
    <StyledButtonLoadingIndicator className="loading">
      {children || <StyledSpinner />}
    </StyledButtonLoadingIndicator>
  )
}
