import React from "react"
import Helmet from "react-helmet"

const SeoMetaTags: React.FC<{
  pageTitle?: string
  title?: string
  description?: string
  twitterCard?: string
  image?: string
}> = ({ pageTitle, title, description, image, twitterCard }) => {
  return (
    <Helmet>
      {pageTitle && <title>{pageTitle}</title>}
      {title && <meta property="og:title" content={title} />}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {description && <meta name="twitter:description" content={description} />}
      {twitterCard && <meta name="twitter:card" content={twitterCard} />}
      {image && <meta property="og:image" content={image} />}
      {image && <meta name="twitter:image" content={image} />}
    </Helmet>
  )
}

export default SeoMetaTags
