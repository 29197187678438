import React from "react"
import styled from "styled-components"
import CloseIcon from "../../../assets/inline-assets/close.svg"

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(216, 216, 216, 0.3);

  svg {
    width: 14px;
    height: 14px;

    g {
      stroke: #d4d4d4;
    }
  }
`

const CloseButton: React.FC<React.PropsWithoutRef<
  JSX.IntrinsicElements["button"]
>> = props => (
  <StyledButton {...props}>
    <CloseIcon />
  </StyledButton>
)

export default CloseButton
