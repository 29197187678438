import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import theme from "../../ui/theme"
import HamburgerButton from "../../ui/buttons/HamburgerButton/HamburgerButton"
import LogoButton from "../../ui/buttons/LogoButton/LogoButton"
import { useIsDarkTheme } from "../ThemeHandler/ThemeHandler"
import SiteMenu from "../SiteMenu/SiteMenu"
import { ROUTES } from "../../navigation/routes"
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  StyledDesktopOnly,
} from "../../ui/responsive"
import { desktopMaxWidthContentCss } from "../../ui/components/MaxWidthContent/MaxWidthContent"
import { bgImageCss } from "../../ui/shared"
import { useIsUserAuthenticated } from "../../auth/components/AuthHandler/AuthHandler"
import { useUserInfo } from "../../auth/components/AuthHandler/hooks"
import SearchIcon from "../../assets/inline-assets/search.svg"
import { useSiteContext } from "../SiteWrapper/components/SiteContextWrapper/SiteContextWrapper"
import { isFirebasePrdEnv } from "../../utils/env"

const StyledHeader = styled.header`
  height: ${theme.dimensions.mobileHeaderHeight}px;

  ${DESKTOP_BREAKPOINT} {
    height: ${theme.dimensions.desktopHeaderHeight}px;
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${theme.zIndexes.header};

  ${DESKTOP_BREAKPOINT} {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 132px;
      right: 132px;
      bottom: 0;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.12);
    }
  }

  ${MOBILE_BREAKPOINT} {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
  }
`

const StyledContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${theme.colors.white};
  position: relative;
  ${MOBILE_BREAKPOINT} {
    background: ${props =>
      props.theme.darkTheme ? theme.colors.darkGrey : theme.colors.white};
  }
`

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  ${DESKTOP_BREAKPOINT} {
    ${desktopMaxWidthContentCss};
  }
`

const StyledLogoWrapper = styled.div`
  ${MOBILE_BREAKPOINT} {
    margin-left: 15px;
  }
`

const StyledMenuButton = styled(HamburgerButton)`
  ${MOBILE_BREAKPOINT} {
    margin-right: 16px;
  }

  ${DESKTOP_BREAKPOINT} {
    margin-right: -10px;
  }
`

const StyledRightSide = styled.div`
  display: flex;
  align-items: center;
`

const StyledUserLink = styled.div`
  display: block;
  margin-right: 20px;
`

const StyledUserThumb = styled.div<{
  image: string
}>`
  ${bgImageCss};
  background-image: url(${props => props.image});
  width: 26px;
  height: 26px;
  border-radius: 50%;
`

const StyledSignedOutLinks = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  a {
    color: ${theme.colors.lightGrey};
    font-size: ${theme.fontSizes.smallish}px;
    line-height: ${theme.lineHeights.smallish}px;
    &:not(:first-child) {
      margin-left: 24px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`

const StyledSearchButton = styled.button<{
  authenticated: boolean
}>`
  margin-right:  ${props => props.authenticated ? 30 : 24}px;

  svg {
    position: relative;
    top: 1px;
  }
`

const SiteHeader: React.FC<{
  hideHeaderOptions?: boolean
}> = ({ hideHeaderOptions = false }) => {
  const darkTheme = useIsDarkTheme()
  const authenticated = useIsUserAuthenticated()
  const { image } = useUserInfo()
  const [menuOpen, setMenuOpen] = useState(false)
  const { navDisabled } = useSiteContext()
  const logoProps = navDisabled
    ? {
        as: "div",
      }
    : {
        as: Link,
        to: ROUTES.home.path,
      }
  return (
    <>
      <StyledHeader>
        <StyledContentWrapper>
          <StyledContent>
            <StyledLogoWrapper>
              <LogoButton fullLogo={darkTheme} {...logoProps} />
            </StyledLogoWrapper>
            <StyledRightSide>
              {!hideHeaderOptions && (
                <>
                  <StyledDesktopOnly>
                    <StyledSignedOutLinks>
                      {!authenticated && (
                        <Link to={ROUTES.signUp.path}>Sign Up</Link>
                      )}
                      {!authenticated && (
                        <Link to={ROUTES.signIn.path}>Sign In</Link>
                      )}
                    </StyledSignedOutLinks>
                  </StyledDesktopOnly>
                  <StyledSearchButton as={Link} to={ROUTES.discover.path} authenticated={authenticated}>
                    <SearchIcon />
                  </StyledSearchButton>
                  {authenticated && (
                    <StyledUserLink as={Link} to={ROUTES.account.path}>
                      <StyledUserThumb image={image} />
                    </StyledUserLink>
                  )}
                </>
              )}

              {!navDisabled && (
                <StyledMenuButton
                  onClick={() => {
                    setMenuOpen(true)
                  }}
                />
              )}
            </StyledRightSide>
          </StyledContent>
        </StyledContentWrapper>
      </StyledHeader>
      {menuOpen && (
        <SiteMenu
          onClose={() => {
            setMenuOpen(false)
          }}
        />
      )}
    </>
  )
}

export default SiteHeader
