import React from "react"
import { get } from "lodash"
import { getSeoPageMetaProps, ICMSPageMeta } from "../../../data/cms/socialMeta"
import { getEnvKey } from "../../../utils/env"
import SeoMetaTags from "../SeoMetaTags/SeoMetaTags"

export interface CMSWebMetaSocial {
  datoCmsWebMetaSocialDev?: {
    screenMetaTags: ICMSPageMeta
  }
  datoCmsWebMetaSocialStg?: {
    screenMetaTags: ICMSPageMeta
  }
  datoCmsWebMetaSocial?: {
    screenMetaTags: ICMSPageMeta
  }
  datoCmsWebMetaSocialPrd?: {
    screenMetaTags: ICMSPageMeta
  }
}

interface Data extends CMSWebMetaSocial {
  [key: string]: any
}

const PageMetaTags: React.FC<{
  data: Data
}> = ({ data }) => {
  const metaKey = getEnvKey({
    devKey: "datoCmsWebMetaSocialDev",
    stgKey: "datoCmsWebMetaSocialStg",
    uatKey: "datoCmsWebMetaSocial",
    prdKey: "datoCmsWebMetaSocialPrd",
    defaultKey: "datoCmsWebMetaSocial",
  })
  const metaData: ICMSPageMeta = get(data, `data.${metaKey}.screenMetaTags`)
  // console.log("metaData", metaData)
  return <SeoMetaTags {...getSeoPageMetaProps(metaData)} />
}

export default PageMetaTags
