/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState } from "react"
import SignUpPrompt from "../SignUpPrompt/SignUpPrompt"

export interface ModalsHandlerContextState {
  setSignUpPrompt: (value: ISignUpPromptData | null) => void
}

export const ModalsHandlerContext = React.createContext<
  ModalsHandlerContextState
>({
  setSignUpPrompt: () => {},
})

export const useModalsHandlerContext = (): ModalsHandlerContextState => {
  return useContext(ModalsHandlerContext)
}

export interface ISignUpPromptData {
  destination: string
  coachSlug?: string
  coachId?: string
  coachName?: string
  coachPrice?: string
}

const ModalsHandler: React.FC = ({ children }) => {
  const [signUpPrompt, setSignUpPrompt] = useState<ISignUpPromptData | null>(
    null
  )
  return (
    <ModalsHandlerContext.Provider
      value={{
        setSignUpPrompt,
      }}
    >
      {children}
      {signUpPrompt && (
        <SignUpPrompt
          destination={signUpPrompt ? signUpPrompt.destination : ""}
          name={signUpPrompt ? signUpPrompt.coachName : ""}
          coachId={signUpPrompt ? signUpPrompt.coachId : ""}
          coachSlug={signUpPrompt ? signUpPrompt.coachSlug : ""}
          coachPrice={signUpPrompt ? signUpPrompt.coachPrice : ""}
        />
      )}
    </ModalsHandlerContext.Provider>
  )
}

export default ModalsHandler
