import React, { useContext, useState } from "react"

export interface SiteContextState {
  navDisabled: boolean
  setNavDisabled: (disabled: boolean) => void
}

export const SiteContext = React.createContext<SiteContextState>({
  navDisabled: false,
  setNavDisabled: () => {},
})

export const useSiteContext = (): SiteContextState => {
  return useContext(SiteContext)
}

const SiteContextWrapper: React.FC = ({ children }) => {
  const [navDisabled, setNavDisabled] = useState(false)
  return (
    <SiteContext.Provider
      value={{
        navDisabled,
        setNavDisabled,
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}

export default SiteContextWrapper
