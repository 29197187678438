import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import MediumPlusHeading from "../../ui/typography/MediumPlusHeading/MediumPlusHeading"
import BodyText from "../../ui/typography/BodyText/BodyText"
import theme from "../../ui/theme"
import { SmallerActionButton } from "../../ui/buttons/ActionButton/ActionButton"
import { ROUTES } from "../../navigation/routes"
import AuthRestricted from "../AuthRestricted/AuthRestricted"
import { DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT } from "../../ui/responsive"
import { useSiteContent } from "../SiteWrapper/components/SiteContentWrapper/SiteContentWrapper"
import { StyledAppleButton } from "../../screens/CoachSignUpScreen/components/DarkSection/DarkSection"

const StyledContainer = styled.section<{
  compact: boolean
}>`
  background: ${theme.colors.darkGrey};
  color: ${theme.colors.white};
  
  ${MOBILE_BREAKPOINT} {
    padding-top: ${props => (props.compact ? "" : "40px")};
  }
`

const StyledHeading = styled(MediumPlusHeading)`
  margin-bottom: ${theme.spacing.small}px;
`

const StyledCopySection = styled.div``

const StyledActionSection = styled.section<{
  compact: boolean
}>`
  padding-top: ${props => (props.compact ? "28px" : "84px")};

  ${DESKTOP_BREAKPOINT} {
    padding-top: ${props => (props.compact ? "56px" : "93.5px")};
  }
`

const StyledLogoWrapper = styled.div<{
  compact: boolean
}>`
  padding-bottom: ${props => (props.compact ? "28px" : "71.5px")};
  display: flex;
  justify-content: center;

  ${DESKTOP_BREAKPOINT} {
    padding-bottom: ${props => (props.compact ? "28px" : "144px")};
  }

  img {
    display: block;
    width: 223px;
  }
`

const StyledOptionsWrapper = styled.div<{
  dark: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -20px;
  background-color: ${props => (props.dark ? theme.colors.darkGrey : "")};
`

export const StyledExploreButton = styled(SmallerActionButton)`
  //min-width: 180px;
  padding-left: 44px;
  padding-right: 44px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 4px;
  background: ${theme.colors.primary};
`

const StyledLoginButton = styled(StyledExploreButton)`
  //padding-left: 36px;
  //padding-right: 36px;
  //min-width: 0;
  font-weight: 400;
  background: ${theme.colors.secondary};
`

const StyledExploreButtonWrapper = styled.div`
  ${MOBILE_BREAKPOINT} {
    flex: 1;
  }

  &:not(:first-child) {
    margin-left: 25px;
  }

  &:first-child:last-child {
    max-width: 216px;
  }
`

const DefaultCTAButton: React.FC = () => {
  const {
    defaultFooterCta2Label,
    defaultFooterCta2Destination,
  } = useSiteContent()
  return (
    <StyledExploreButton as="a" href={defaultFooterCta2Destination}>
      {defaultFooterCta2Label}
    </StyledExploreButton>
  )
}

const DefaultSignInButton: React.FC = () => {
  const {
    defaultFooterCta1Label,
    defaultFooterCta1Destination,
  } = useSiteContent()
  return (
    <StyledLoginButton as="a" href={defaultFooterCta1Destination}>
      {defaultFooterCta1Label}
    </StyledLoginButton>
  )
}

export const YouspiredFooterCTA: React.FC<{
  dark?: boolean
  ctaButton?: any
}> = ({ dark = false, ctaButton = <DefaultCTAButton /> }) => {
  return (
    <StyledOptionsWrapper dark={dark}>
      {/* <AuthRestricted authenticatedOnly={false}> */}
      {/*  <div> */}
      {/*    <DefaultSignInButton /> */}
      {/*  </div> */}
      {/* </AuthRestricted> */}
      {/* <StyledExploreButtonWrapper>{ctaButton}</StyledExploreButtonWrapper> */}
    </StyledOptionsWrapper>
  )
}

const AboutYouspired: React.FC<{
  includeCopy?: boolean
  includeLogo?: boolean
  ctaButton?: any
  isCompact?: boolean
}> = ({ includeCopy = true, includeLogo = true, ctaButton, isCompact = false }) => (
  <StyledContainer compact={isCompact}>
    {includeCopy && (
      <StyledCopySection>
        <StyledHeading as="h4">About Youspired</StyledHeading>
        <BodyText>
          <p>
            Youspired is a platform where leading thinkers, coaches and creators
            deliver the truth about business & life. Coaches share regular
            live videos designed to inspire and invigorate their subscribers.
          </p>
          <p>
            Every video is unscripted with real talk. You can interact with
            coaches in the comments and ask questions to make the most of every
            live.
          </p>
        </BodyText>
      </StyledCopySection>
    )}

    {includeLogo && (
      <StyledActionSection compact={isCompact}>
        <StyledLogoWrapper compact={isCompact}>
          <img src="/svgs/youspiredWhite.svg" alt="Youspired Logo" />
        </StyledLogoWrapper>
      </StyledActionSection>
    )}
    <YouspiredFooterCTA ctaButton={ctaButton} />
  </StyledContainer>
)

export default AboutYouspired
