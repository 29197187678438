import React from "react"
import Helmet from "react-helmet"
import parse from "html-react-parser"
import { graphql, useStaticQuery } from "gatsby"
import { get } from "lodash"
import { getSeoProps, ICMSSeoTags } from "../../data/cms/socialMeta"
import { getEnvKey } from "../../utils/env"
import SeoMetaTags from "./SeoMetaTags/SeoMetaTags"

export const SiteMetaHelmet: React.FC<{
  defaultTitle?: string
  titleTemplate?: string
}> = ({
  children,
  defaultTitle = "Youspired",
  titleTemplate = "Youspired - %s",
}) => (
  <Helmet defaultTitle={defaultTitle} titleTemplate={titleTemplate}>
    {children}
  </Helmet>
)

const SiteMeta: React.FC = () => {
  const response: {
    datoCmsWebMetaSocialDev?: {
      defaultPageTitle: string
      defaultPageTitleTemplate: string
      defaultMetaTags: ICMSSeoTags
      defaultRawMetaTags: string
    }
    datoCmsWebMetaSocialStg?: {
      defaultPageTitle: string
      defaultPageTitleTemplate: string
      defaultMetaTags: ICMSSeoTags
      defaultRawMetaTags: string
    }
    datoCmsWebMetaSocial?: {
      defaultPageTitle: string
      defaultPageTitleTemplate: string
      defaultMetaTags: ICMSSeoTags
      defaultRawMetaTags: string
    }
    datoCmsWebMetaSocialPrd?: {
      defaultPageTitle: string
      defaultPageTitleTemplate: string
      defaultMetaTags: ICMSSeoTags
      defaultRawMetaTags: string
    }
  } = useStaticQuery(graphql`
    query SiteMetaQuery {
      datoCmsWebMetaSocialDev {
        defaultPageTitle
        defaultPageTitleTemplate
        defaultMetaTags {
          ...SeoTags
        }
        defaultRawMetaTags
      }
      datoCmsWebMetaSocialStg {
        defaultPageTitle
        defaultPageTitleTemplate
        defaultMetaTags {
          ...SeoTags
        }
        defaultRawMetaTags
      }
      datoCmsWebMetaSocial {
        defaultPageTitle
        defaultPageTitleTemplate
        defaultMetaTags {
          ...SeoTags
        }
        defaultRawMetaTags
      }
      datoCmsWebMetaSocialPrd {
        defaultPageTitle
        defaultPageTitleTemplate
        defaultMetaTags {
          ...SeoTags
        }
        defaultRawMetaTags
      }
    }
  `)
  const dataKey = getEnvKey({
    devKey: "datoCmsWebMetaSocialDev",
    stgKey: "datoCmsWebMetaSocialStg",
    uatKey: "datoCmsWebMetaSocial",
    prdKey: "datoCmsWebMetaSocialPrd",
    defaultKey: "datoCmsWebMetaSocial",
  })
  const defaultMetaRawTags: string =
    get(response, `${dataKey}.defaultRawMetaTags`) || ""
  const defaultMetaTags: ICMSSeoTags = get(
    response,
    `${dataKey}.defaultMetaTags`
  )
  const title: string = get(response, `${dataKey}.defaultPageTitle`) || ""
  const titleTemplate: string =
    get(response, `${dataKey}.defaultPageTitleTemplate`) || ""
  return (
    <>
      <SiteMetaHelmet defaultTitle={title} titleTemplate={titleTemplate}>
        <link
          href="https://fonts.googleapis.com/css?family=Public+Sans:400,500,600,700&display=swap"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="https://use.typekit.net/mhu5iei.css" />
        {/* https://github.com/CookPete/react-player/issues/605 */}
        <script src="https://cdnjs.cloudflare.com/ajax/libs/hls.js/0.12.4/hls.min.js" />
        {parse(defaultMetaRawTags)}
      </SiteMetaHelmet>
      <SeoMetaTags {...getSeoProps(defaultMetaTags)} />
    </>
  )
}

export default SiteMeta
