import { css } from "styled-components"
import theme from "../theme"

export const fancyCondensedFontCss = css`
  font-family: ${theme.fonts.acuminProExtraCondensed};
  font-weight: 400;
`

export const fancyWideFontCss = css`
  font-family: ${theme.fonts.acuminProWide};
`
