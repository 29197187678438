import React from "react"
import styled, { css } from "styled-components"
import { transparentize } from "polished"
import MaxWidthContent from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import CloseButton from "../../../../ui/buttons/CloseButton/CloseButton"
import RawModal from "../../../../ui/components/RawModal/RawModal"
import theme from "../../../../ui/theme"

const StyledContainer = styled.div<{
  opaque: boolean
}>`
  background-color: ${props =>
    props.opaque
      ? transparentize(0.42, theme.colors.darkGrey)
      : theme.colors.darkGrey};
  color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.large}px;
  padding: 45px;
  position: relative;
`

const StyledContent = styled.div`
  padding-top: 15px;
  padding-bottom: 5px;
`

const StyledCloseWrapper = styled.div`
  position: absolute;
  top: 18px;
  right: 16px;
`

const widerCss = css`
  max-width: 470px;
`

const StyledWrapper = styled(MaxWidthContent)<{
  wider: boolean
}>`
  ${props => (props.wider ? widerCss : "")};
`

const PopupPrompt: React.FC<{
  disableClose: boolean
  onClose: () => void
  opaque: boolean
  footer?: any
  wider?: boolean
}> = ({ disableClose, onClose, footer, opaque, wider = false, children }) => {
  const handleClose = () => {
    if (disableClose) return
    onClose()
  }
  return (
    <RawModal onClose={handleClose}>
      <StyledWrapper wider={wider}>
        <StyledContainer opaque={opaque}>
          {!disableClose && (
            <StyledCloseWrapper>
              <CloseButton onClick={handleClose} />
            </StyledCloseWrapper>
          )}
          <StyledContent>{children}</StyledContent>
        </StyledContainer>
        {footer}
      </StyledWrapper>
    </RawModal>
  )
}

export default PopupPrompt
