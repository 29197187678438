import React from "react"
import SiteMeta from "../SiteMeta/SiteMeta"
import ErrorBoundary from "../../errors/components/ErrorBoundary/ErrorBoundary"
import ThemeHandler from "../ThemeHandler/ThemeHandler"
import ModalsHandler from "../ModalsHandler/ModalsHandler"
import AuthHandler from "../../auth/components/AuthHandler/AuthHandler"
import SiteContextWrapper from "./components/SiteContextWrapper/SiteContextWrapper"
import SiteContentWrapper from "./components/SiteContentWrapper/SiteContentWrapper"

interface Props {
  darkTheme?: boolean
}

const SiteWrapper: React.FC<Props> = ({ children, darkTheme = false }) => (
  <ErrorBoundary>
    <SiteContextWrapper>
      <SiteContentWrapper>
        <AuthHandler>
          <ThemeHandler darkTheme={darkTheme}>
            <SiteMeta />
            <ModalsHandler>{children}</ModalsHandler>
          </ThemeHandler>
        </AuthHandler>
      </SiteContentWrapper>
    </SiteContextWrapper>
  </ErrorBoundary>
)

export default SiteWrapper
