import React, { useState } from "react"
import { get } from "lodash"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import PopupPrompt from "../SignUpPrompt/components/PopupPrompt/PopupPrompt"
import { StyledInput as StyledValidateInput } from "../../ui/inputs/ValidatedInput/ValidatedInput"
import ActionButton, {
  ButtonLoadingIndicator,
} from "../../ui/buttons/ActionButton/ActionButton"
import theme from "../../ui/theme"
import { placeholderCss } from "../../ui/helpers"
import LargeHeading from "../../ui/typography/LargeHeading/LargeHeading"
import { StyledDescription } from "../CancelSubscriptionPrompt/CancelSubscriptionPrompt"
import { firebaseSendPasswordResetEmail } from "../../firebase/auth"
import { StyledOptionWrapper } from "../../screens/SignUpScreen/views/CreateStep/views/SelectSignUpOptionView/SelectSignUpOptionView"
import { getHelpUrl, ICMSMetaSocial } from "../../data/cms/socialMeta"
import { getEnvKey } from "../../utils/env"

export const useHelpUrl = (): string => {
  const response: {
    datoCmsWebMetaSocialDev?: ICMSMetaSocial
    datoCmsWebMetaSocialStg?: ICMSMetaSocial
    datoCmsWebMetaSocial?: ICMSMetaSocial
  } = useStaticQuery(graphql`
    query HelpUrlQuery {
      datoCmsWebMetaSocialDev {
        helpUrl
      }
      datoCmsWebMetaSocialStg {
        helpUrl
      }
      datoCmsWebMetaSocial {
        helpUrl
      }
      datoCmsWebMetaSocialPrd {
        helpUrl
      }
    }
  `)
  const dataKey = getEnvKey({
    devKey: "datoCmsWebMetaSocialDev",
    stgKey: "datoCmsWebMetaSocialStg",
    uatKey: "datoCmsWebMetaSocial",
    prdKey: "datoCmsWebMetaSocialPrd",
    defaultKey: "datoCmsWebMetaSocial",
  })

  const data: ICMSMetaSocial = get(response, `${dataKey}`)

  return getHelpUrl(data)
}

export const StyledInput = styled(StyledValidateInput)`
  border-color: ${theme.colors.lightGrey};
  padding: 13.5px 16px;
  ${placeholderCss(`
    color: ${theme.colors.lightGrey};
  `)};
`

const StyledErrorMessage = styled.div`
  color: ${theme.colors.primary};
`

const StyledHelpUrlWrapper = styled.div`
  margin-top: 32px;
  text-align: center;

  a {
    text-decoration: underline;
  }
`

const ForgotDetailsModal: React.FC<{
  initialEmail: string
  onClose: () => void
}> = ({ initialEmail, onClose }) => {
  const helpUrl = useHelpUrl()

  const [email, setEmail] = useState(initialEmail)
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<{
    code: string
    message: string
  }>()
  const [success, setSuccess] = useState(false)

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (busy) return
    setBusy(true)
    firebaseSendPasswordResetEmail(email)
      .then(() => {
        setSuccess(true)
      })
      .catch(promiseError => {
        console.error(promiseError)
        Sentry.captureMessage(
          "Something went wrong when sending password reset email"
        )
        Sentry.captureException(promiseError)
        setError(promiseError)
      })
      .finally(() => {
        setBusy(false)
      })
  }

  return (
    <PopupPrompt disableClose={false} onClose={onClose} opaque={false} wider>
      {success ? (
        <div>
          A password reset link has been sent to <strong>{email}</strong>
        </div>
      ) : (
        <form onSubmit={onSubmit} noValidate>
          <LargeHeading>Forgot password?</LargeHeading>
          <StyledDescription>
            Enter your email address to receive a password reset link.
          </StyledDescription>
          <StyledOptionWrapper>
            <StyledInput
              value={email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value)
              }}
              type="email"
              placeholder="Email"
            />
          </StyledOptionWrapper>
          <StyledOptionWrapper>
            <ActionButton type="submit" loading={busy}>
              Submit
              {busy && <ButtonLoadingIndicator />}
            </ActionButton>
          </StyledOptionWrapper>
          {error && (
            <StyledOptionWrapper>
              <StyledErrorMessage>
                {get(error, "message", "")}
              </StyledErrorMessage>
            </StyledOptionWrapper>
          )}
        </form>
      )}
      <StyledHelpUrlWrapper>
        <a href={helpUrl} target="_blank" rel="noopener noreferrer">
          Help
        </a>
      </StyledHelpUrlWrapper>
    </PopupPrompt>
  )
}

export default ForgotDetailsModal
