import React from "react"
import styled from "styled-components"
import PopupPrompt from "../SignUpPrompt/components/PopupPrompt/PopupPrompt"
import LargeHeading from "../../ui/typography/LargeHeading/LargeHeading"
import ActionButton, {
  ButtonLoadingIndicator,
} from "../../ui/buttons/ActionButton/ActionButton"

export const StyledDescription = styled.p`
  margin-top: 8px;
  margin-bottom: 23.5px;

  a {
    text-decoration: underline;
  }
`

const CancelSubscriptionPrompt: React.FC<{
  onClose: () => void
  busy: boolean
  onCancel: () => void
}> = ({ onClose, busy, onCancel }) => {
  return (
    <PopupPrompt onClose={onClose} disableClose={busy} opaque={false}>
      <>
        <LargeHeading>Unsubscribe.</LargeHeading>
        <StyledDescription>
          Are you sure you want
          <br /> to unsubscribe?
        </StyledDescription>
        <div>
          <ActionButton onClick={onCancel} loading={busy}>
            I understand. Unsubscribe.
            {busy && <ButtonLoadingIndicator />}
          </ActionButton>
        </div>
      </>
    </PopupPrompt>
  )
}

export default CancelSubscriptionPrompt
