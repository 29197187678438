import React from "react"
import styled, { css } from "styled-components"
import { StyledButton } from "../HamburgerButton/HamburgerButton"
import LogoIcon from "../../../assets/inline-assets/youspired_mark_large_coral.svg"
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  StyledDesktopOnly,
  StyledMobileOnly,
} from "../../responsive"

const fullLogoCss = css`
  width: auto;

  ${MOBILE_BREAKPOINT} {
    padding-left: 8px;
  }

  img {
    display: block;
  }
`

const StyledLogoButton = styled(StyledButton)<{
  fullLogo: boolean
}>`
  display: flex;
  align-items: center;

  img {
    display: none;
  }

  ${props => (props.fullLogo ? fullLogoCss : "")}

  ${DESKTOP_BREAKPOINT} {
    ${fullLogoCss};
  }
`

const StyledLogoImage = styled.img`
  width: 106.5px;
  margin-left: 8px;
`

interface Props {
  as?: any
  to?: string
  fullLogo?: boolean
  desktopHideFull?: boolean
}

const LogoButton: React.FC<Props> = ({
  fullLogo = false,
  desktopHideFull = false,
  ...props
}) => {
  return (
    <StyledLogoButton {...props} fullLogo={fullLogo} iconWidth={26}>
      <LogoIcon />
      <StyledMobileOnly>
        <StyledLogoImage src="/svgs/youspiredWhite.svg" alt="Youspired Logo" />
      </StyledMobileOnly>
      {!desktopHideFull && (
        <StyledDesktopOnly>
          <StyledLogoImage
            src="/svgs/youspiredBlack.svg"
            alt="Youspired Logo"
          />
        </StyledDesktopOnly>
      )}
    </StyledLogoButton>
  )
}

export default LogoButton
