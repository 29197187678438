import React from "react"
import styled from "styled-components"
import theme from "../../ui/theme"
import { ResponsiveMaxWidthContent } from "../../ui/components/MaxWidthContent/MaxWidthContent"
import {
  DESKTOP_BREAKPOINT,
  showOnDesktopCss,
  StyledDesktopOnly,
} from "../../ui/responsive"
import SocialLinks, { useSocialLinks } from "../SocialLinks/SocialLinks"
import YSMark from "../../assets/inline-assets/youspired_mark_large_coral.svg"
import { ROUTES } from "../../navigation/routes"
import { useHelpUrl } from "../ForgotDetailsModal/ForgotDetailsModal"
import ActionButton from "../../ui/buttons/ActionButton/ActionButton"
import AuthRestricted from "../AuthRestricted/AuthRestricted"
import AppleIcon from "../../ui/icons/AppleIcon/AppleIcon"

const StyledFooter = styled.footer`
  background: ${theme.colors.darkGrey};
  color: ${theme.colors.white};
  padding: 15px 0;
  position: relative;
`

const StyledContainer = styled(ResponsiveMaxWidthContent)`
  ${DESKTOP_BREAKPOINT} {
    display: flex;
    justify-content: space-between;
  }
`

const StyledCopyright = styled.div`
  font-size: ${theme.fontSizes.tiny}px;
  line-height: ${theme.lineHeights.smallish}px;
  padding-right: 12px;
  
  ${DESKTOP_BREAKPOINT} {
    font-size: ${theme.fontSizes.small}px;
  }
`

const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledLogo = styled.div`
  ${showOnDesktopCss};
  margin-right: 8.5px;
  svg {
    width: 13px;
    height: auto;

    g {
      fill: ${theme.colors.white};
    }
  }
`

const StyledFooterLink = styled.div`
  font-size: ${theme.fontSizes.tiny}px;
  line-height: ${theme.lineHeights.smallish}px;
  padding: 0 12px;
  
  &:hover {
    text-decoration: underline;
  }

  ${DESKTOP_BREAKPOINT} {
    font-size: ${theme.fontSizes.small}px;
  }
`

const StyledActionButton = styled(ActionButton)`
  margin-bottom: ${theme.spacing.medium}px;
  
  ${DESKTOP_BREAKPOINT} {
    display: none;
  }
`

const SiteFooter: React.FC = () => {
  const { downloadApp } = useSocialLinks()
  return (
  <StyledFooter>
    <AuthRestricted>
      <StyledContainer>
        <StyledActionButton as="a" href={downloadApp} target="_blank"><AppleIcon whiteLogo/> Download iPhone App</StyledActionButton>
      </StyledContainer>
    </AuthRestricted>
    <StyledContainer>
      <StyledLogoWrapper>
        <StyledLogo>
          <YSMark/>
        </StyledLogo>
        <StyledCopyright>©Youspired</StyledCopyright>
        <StyledFooterLink as="a" href={ROUTES.terms.path}>Terms</StyledFooterLink>
        <StyledFooterLink as="a" href={ROUTES.privacy.path}>Privacy</StyledFooterLink>
        <StyledFooterLink as="a" href={useHelpUrl()} target="_blank">Help</StyledFooterLink>
      </StyledLogoWrapper>
      <StyledDesktopOnly>
        <SocialLinks/>
      </StyledDesktopOnly>
    </StyledContainer>
  </StyledFooter>
)
}

export default SiteFooter
