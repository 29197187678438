import React from "react"
import styled from "styled-components"
import HamburgerIcon from "../../../assets/inline-assets/hamburger.svg"

export const StyledButton = styled.button<{
  iconWidth: number
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  svg {
    display: block;
    width: ${props => props.iconWidth}px;
    height: ${props => props.iconWidth}px;
  }
`

const HamburgerButton: React.FC<any> = props => (
  <StyledButton {...props} iconWidth={21}>
    <HamburgerIcon />
  </StyledButton>
)

export default HamburgerButton
