import React from "react"
import { get } from "lodash"
import styled from "styled-components"
import theme from "../../../../../../../../ui/theme"

export const StyledFirebaseError = styled.div`
  color: ${theme.colors.primary};
  margin-top: ${theme.spacing.small}px;
`

const FirebaseErrorMessage: React.FC<{
  error: Error
}> = ({ error }) => {
  const code = get(error, "code", "")
  const message = get(error, "message", "")

  if (code === "auth/account-exists-with-different-credential") {
    const email = get(error, "email", "")
    return (
      <StyledFirebaseError>
        {message} <br />
        Email: {email}
      </StyledFirebaseError>
    )
  }

  return <StyledFirebaseError>{message}</StyledFirebaseError>
}

export default FirebaseErrorMessage
