import { get } from "lodash"
import { firebaseDatabase } from "./client"

export interface IFirestoreOfferingPrice {
  active: boolean
  currency: string
  id: string
  product: string
  recurring: {
    interval: string
    interval_count: number
    trial_period_days: number
  }
  type: string
  unit_amount: number
  unit_amount_decimal: string
}

export interface IFirestoreOfferingProduct {
  active: boolean
  id: string
  name: string
  object: string
}

export interface IFirestoreOfferingTax {
  active: boolean
  display_name: string
  inclusive: boolean
  jurisdiction: string
  object: string
  percentage: number
}

export interface IFirestoreOfferingTaxes {
  au?: IFirestoreOfferingTax
}

export interface IFirestoreOfferingDoc {
  stripePrice: IFirestoreOfferingPrice
  stripeProduct: IFirestoreOfferingProduct
  stripeTax?: IFirestoreOfferingTaxes
}

export const getFirestoreOfferingStripePriceAmount = (
  offering: IFirestoreOfferingDoc | null
): number => {
  return get(offering, "stripePrice.unit_amount", "") || ""
}

export const getFirestoreOfferingStripeCountryTax = (
  offering: IFirestoreOfferingDoc | null,
  countryCode: string
): IFirestoreOfferingTax => {
  return get(offering, `stripeTax.${countryCode.toLowerCase()}`, "") || ""
}

export const getFirestoreOfferingStripeCountryTaxPercentage = (
  offeringTax: IFirestoreOfferingTax
): number => {
  return get(offeringTax, "percentage", 0) || 0
}

export const offeringsCollection = firebaseDatabase.collection("offerings")

export const getOfferingDoc = (coachId: string) => {
  return offeringsCollection.doc(coachId)
}

export const getCoachStripeOffering = (
  coachId: string
): Promise<IFirestoreOfferingDoc | null> => {
  return getOfferingDoc(coachId)
    .get()
    .then(snapshot => {
      const firestoreOffering:
        | IFirestoreOfferingDoc
        | undefined = snapshot.data() as IFirestoreOfferingDoc
      if (!firestoreOffering) return null
      return firestoreOffering
    })
}
