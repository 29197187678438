import * as firebase from "firebase/app"
import { firebaseApp } from "./client"
import { setUserTermsAccepted } from "./user"
import {
  mixpanelAlias,
  mixpanelEventCreateAccount,
  mixpanelSetProfileSignupDetails,
} from "../analytics/mixpanel"
import { gaEventAccountSignUp } from "../analytics/google"

const firebaseFacebookProvider = new firebase.auth.FacebookAuthProvider()
const firebaseAppleProvider = new firebase.auth.OAuthProvider("apple.com")

firebaseAppleProvider.addScope("email")

export const firebaseApplyActionCode = (code: string) => {
  return firebase.auth().applyActionCode(code)
}

export const firebaseResetPassword = (password: string, code: string) => {
  return firebase.auth().confirmPasswordReset(code, password)
}

export const firebaseSendPasswordResetEmail = (email: string) => {
  return firebase.auth().sendPasswordResetEmail(email)
}

export const firebaseFacebookSignInWithRedirect = () => {
  firebaseApp.auth().signInWithRedirect(firebaseFacebookProvider)
}

export const firebaseFacebookSignInWithPopup = () => {
  return firebaseApp.auth().signInWithPopup(firebaseFacebookProvider)
}

export const firebaseAppleSignInWithRedirect = () => {
  firebaseApp.auth().signInWithRedirect(firebaseAppleProvider)
}

export const firebaseAppleSignInWithPopup = () => {
  return firebaseApp.auth().signInWithPopup(firebaseAppleProvider)
}

export const isIOS = (): boolean => {
  if (typeof window === `undefined` || typeof navigator === "undefined") {
    return true
  }
  return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
}

export const shouldSignInWithPopup = (): boolean => {
  return true
  // return isIOS() || isDesktopSize()
}

export const firebaseSignInWithEmailAndPassword = (
  email: string,
  password: string
) => {
  return firebaseApp.auth().signInWithEmailAndPassword(email, password)
}

export const firebaseSignUpWithEmailAndPassword = (
  email: string,
  password: string,
  mixpanel: any
) => {
  return firebaseApp
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(result => {
      if (result && result.user) {
        mixpanelAlias(mixpanel, result.user.uid)
        mixpanelSetProfileSignupDetails(mixpanel, result.user.uid, "email")
        gaEventAccountSignUp("Email")
        mixpanelEventCreateAccount(mixpanel)
        setTimeout(() => {
          setUserTermsAccepted().catch(() => {
            // potentially failed due to doc not existing yet, so shall try again
            setTimeout(() => {
              setUserTermsAccepted()
            }, 1000)
          })
        }, 1000)
      }
    })
}
