import React from "react"
import styled from "styled-components"
import AppleLogo from "../../../assets/inline-assets/appleLogo.svg"
import theme from "../../theme"

const StyledWrapper = styled.span<{
  whiteLogo: boolean
}>`
  svg {
    display: inline-block;
    width: 13px;
    height: auto;
    margin-right: 7px;
    position: relative;
    top: 1px;
    
    path {
      fill: ${props => (props.whiteLogo ? theme.colors.white : "")};
    }
    
  }
`

const AppleIcon: React.FC<{
  whiteLogo?: boolean
}> = ({whiteLogo = false}) => {
  return (
    <StyledWrapper whiteLogo={whiteLogo}>
      <AppleLogo />
    </StyledWrapper>
  )
}

export default AppleIcon
