import styled from "styled-components"

const BodyText = styled.div`
  p {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
`

export default BodyText
