import { useEffect, useState } from "react"
import { get } from "lodash"
import { firebaseDatabase } from "./client"
import { IGatsbyVideo } from "./video"

export interface IFirestoreCoachDoc {
  avatarUrl: string
  bio: string
  coachId: string
  slug: string
  name: string
  photoUrl: string
  subscriptionPrice?: number
}

export const getFirestoreCoachSubscriptionPrice = (
  coach: IFirestoreCoachDoc
): number => {
  const price = get(coach, "subscriptionPrice", null) || null
  if (price) {
    return price / 100
  }
  return 0
}

export const coachesCollection = firebaseDatabase.collection("coaches")

export const getCoachDoc = (coachId: string) => {
  return coachesCollection.doc(coachId)
}

export const fetchCoaches = (coachIds: string[]) => {
  const promises = coachIds.map(coachId => {
    const doc = getCoachDoc(coachId)
    return doc.get().then(snapshot => {
      return snapshot.data()
    })
  })
  return Promise.all(promises)
}

export const videosCollection = firebaseDatabase.collection("videos")

export const fetchCoachVideos = (coachId: string) => {
  // todo
  return videosCollection
    .where("coachId", "==", coachId)
    .where("isActive", "==", true)
    .get()
    .then(function(querySnapshot) {
      const vods: IGatsbyVideo[] = []
      querySnapshot.forEach(function(doc) {
        // doc.data() is never undefined for query doc snapshots
        vods.push(doc.data() as IGatsbyVideo)
      })
      // console.log("vods", vods)
      return vods
    })
    .catch(error => {
      console.error("Error getting documents: ", error)
      Sentry.captureMessage(
        "Something went wrong when fetching coach videos from firebase"
      )
      Sentry.captureException(error)
    })
}

export const useCoachVideos = (coachId: string): [IGatsbyVideo[], boolean] => {
  const [loading, setLoading] = useState(true)
  const [vods, setVods] = useState<IGatsbyVideo[]>([])
  useEffect(() => {
    fetchCoachVideos(coachId)
      .then(data => {
        if (data) {
          setVods(data)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])
  return [vods, loading]
}
