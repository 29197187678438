/* eslint-disable no-undef,react/destructuring-assignment,react/prop-types,react/jsx-filename-extension */
import React from "react"

export default class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error })
    Sentry.configureScope((scope: any) => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
    })
    Sentry.captureException(error)
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return <h1>Something went wrong!</h1>
    }
    // when there's not an error, render children untouched
    return this.props.children
  }
}
