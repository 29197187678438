import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { RouteComponentProps } from "@reach/router"
import ActionButton from "../../../../ui/buttons/ActionButton/ActionButton"
import MaxWidthContent from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import theme from "../../../../ui/theme"
import MediumPlusHeading from "../../../../ui/typography/MediumPlusHeading/MediumPlusHeading"
import { StyledPlainList } from "../../../CoachScreen/components/CoachFurtherDetailsSection/CoachFurtherDetailsSection"
import TickIcon from "../../../../assets/inline-assets/tick.svg"
import { ROUTES } from "../../../../navigation/routes"
import { useSignUpBasePath } from "../../components/SignUpContextHandler/SignUpContextHandler"
import { DESKTOP_BREAKPOINT } from "../../../../ui/responsive"
import { useIsUserAuthenticated } from "../../../../auth/components/AuthHandler/AuthHandler"

export const desktopMinHeightCss = css`
  min-height: calc(100vh - 133px);
`

export const minHeightCss = css`
  //min-height: calc(100vh - 116px);
  min-height: calc(100vh - 110px);

  ${DESKTOP_BREAKPOINT} {
    ${desktopMinHeightCss};
  }
`

export const StyledContainer = styled(MaxWidthContent)`
  padding-top: 15px;
  padding-bottom: 6px;
  ${minHeightCss};

  ${DESKTOP_BREAKPOINT} {
    padding-top: 78px;
  }
`

export const StyledStepHeader = styled.header`
  border-bottom: 1px solid ${theme.colors.primary};
  font-size: ${theme.fontSizes.smallish}px;
  line-height: ${theme.lineHeights.smallish}px;
  text-align: center;
  padding-bottom: 8px;
  margin-bottom: 33px;
  position: relative;
`

export const StyledStepHeading = styled(MediumPlusHeading)`
  line-height: 30px;
`

const StyledNote = styled.div`
  font-size: ${theme.fontSizes.smallish}px;
  line-height: 20px;
`

const StyledListContainer = styled.div`
  margin: ${theme.spacing.small}px 0;
`

const StyledActionContainer = styled.div`
  margin-top: ${theme.spacing.small}px;
`

const StyledListItem = styled.div`
  padding: 14px 0;
  display: flex;
  align-items: center;
`

const StyledListItemTick = styled.div`
  margin-right: 17.5px;

  svg {
    width: 22px;

    path {
      stroke: ${theme.colors.primary};
    }
  }
`

interface Props extends RouteComponentProps {
  signUpDestination: string
}

const InfoStep: React.FC<Props> = ({ signUpDestination }) => {
  const basePath = useSignUpBasePath()
  const authenticated = useIsUserAuthenticated()
  const destination = authenticated
    ? `${basePath}${signUpDestination}`
    : `${basePath}${ROUTES.signUpCreate.subPath}`
  return (
    <StyledContainer>
      <StyledStepHeader>{/* Step One */}</StyledStepHeader>
      <div>
        <StyledStepHeading as="h2">How this works</StyledStepHeading>
        <StyledListContainer>
          <StyledPlainList>
            <li>
              <StyledListItem>
                <StyledListItemTick>
                  <TickIcon />
                </StyledListItemTick>
                Get your first 5 days for $1*.
              </StyledListItem>
            </li>
            <li>
              <StyledListItem>
                <StyledListItemTick>
                  <TickIcon />
                </StyledListItemTick>
                No charge until after the first 5 days.
              </StyledListItem>
            </li>
            <li>
              <StyledListItem>
                <StyledListItemTick>
                  <TickIcon />
                </StyledListItemTick>
                We'll remind you 24 hrs before your monthly subscription
                activates.
              </StyledListItem>
            </li>
            <li>
              <StyledListItem>
                <StyledListItemTick>
                  <TickIcon />
                </StyledListItemTick>
                Cancel anytime.
              </StyledListItem>
            </li>
          </StyledPlainList>
        </StyledListContainer>
        <StyledNote>*Subscription is per coach</StyledNote>
      </div>
      <StyledActionContainer>
        <ActionButton as={Link} to={destination}>
          Next
        </ActionButton>
      </StyledActionContainer>
    </StyledContainer>
  )
}

export default InfoStep
