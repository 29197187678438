import styled from "styled-components"
import theme from "../../theme"

const LargeHeading = styled.h3`
  font-size: ${theme.fontSizes.large}px;
  line-height: ${theme.lineHeights.large / theme.fontSizes.large};
  font-weight: 700;
`

export default LargeHeading
